import * as types from "./JwtActionTypes";
import initialState from "../InitialState";

const jwtReducer = (state = initialState.jwtDetails, action) => {
  switch (action.type) {
    case types.CREATE_ADMIN_JWT_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.CREATE_ADMIN_JWT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case types.CREATE_ADMIN_JWT_SUCCEEDED:
      const admin = {
        ...state.admin,
        bearer: action.payload.data,
      };

      return {
        ...state,
        admin: admin,
        isLoading: false,
        error: null,
      };
    case types.CREATE_CORE_JWT_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.CREATE_CORE_JWT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case types.CREATE_CORE_JWT_SUCCEEDED:
      const core = {
        ...state.core,
        bearer: action.payload.data,
      };
      return {
        ...state,
        core: core,
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default jwtReducer;
