import React from "react";

const TeamMember = ({ imgUrl, imgAlt, profileUrl, url, name, position }) => (
  <div className="col-xs-12 col-md-6 col-lg-3 team-member-wrapper">
    <div className="av_person">
      <div className={`${!profileUrl && !url ? "no-profile " : ""}avatar🐱`}>
        <img
          className="rounded-circle"
          src={require("../../../Assets/" + imgUrl).default}
          alt={`${imgAlt}`}
        />
        {profileUrl && (
          <a
            href={`//www.linkedin.com/in/${profileUrl}`}
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <i className="tio linkedin_square"></i>
          </a>
        )}
        {url && (
          <a
            href={`//${url}`}
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <i className="tio website"></i>
          </a>
        )}
      </div>
      <div className="info_name">
        <h4 className="c-dark">{name}</h4>
        <p>{position}</p>
      </div>
    </div>
  </div>
);

export default TeamMember;
