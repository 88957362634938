import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import EmailConfirmation from "./EmailConfirmation";
import TextStyling from "../Page/TextStyling/TextStyling";
import { validateEmail } from "../../../Helpers/Extensions";
import { connect } from "react-redux";
import { subscribe } from "../../../Redux/Newsletter/NewsletterActions";
import LoadingGif from "../../../StaticSite/_Shared/LoadingGif";
import { createAdminJwt } from "../../../Redux/JWT/JwtActions";
import {
  PRIVACY_POLICY_URL,
  SUBSCRIPTION_AGREEMENT_URL,
  TERMS_OF_USE_URL,
} from "../../../Helpers/Constants";

const FooterContact = ({ subscribe, newsletter, createAdminJwt, jwt }) => {
  const subscriptionType = "newsletter_welcome";
  const [initialized, setInitialized] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErrorSuccessModal, setShowErrorSuccessModal] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState("");
  const [subscribing, setSubscribing] = useState(false);

  const NewsletterSubscribe = () => {
    const email = document.getElementById("exampleInputEmail1").value;
    if (validateEmail(email)) {
      setShowModal(true);
    } else {
      setErrorSuccessMsg("Please insert a valid email address.");
      setShowErrorSuccessModal(true);
    }
  };

  const NewsletterSubscribeKeyPress = (event) => {
    if (event.keyCode === 13) {
      NewsletterSubscribe();
    }
  };

  const onConfirmationPressEvent = () => {
    const email = document.getElementById("exampleInputEmail1").value;
    setShowModal(false);
    setSubscribing(true);
    subscribe({ email: email, type: subscriptionType });
  };

  useEffect(() => {
    if (!initialized) {
      if (!jwt.admin.bearer) {
        createAdminJwt();
      }

      setInitialized(true);
    }
  }, [initialized, jwt.admin.bearer, createAdminJwt]);

  useEffect(() => {
    if (!!newsletter && !newsletter.isLoading && subscribing) {
      if (newsletter.data.code === 200) {
        setErrorSuccessMsg(
          "You are subscribed to the Eate Digital newsletter!"
        );
      } else {
        setErrorSuccessMsg(
          "Unfortunately, there was an error adding you to the newsletter. Please email hello@eatedigital.com, and we'll be sure to you add you!"
        );
      }

      setShowErrorSuccessModal(true);
      setSubscribing(false);
      document.getElementById("exampleInputEmail1").value = "";
    }
  }, [newsletter, subscribing]);

  return (
    <div className="sh_footer_email padding-b-8 bg_fitness eate-footer-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="eate-contact-form">
              <h3>
                <TextStyling
                  text={[
                    {
                      text: "Stay in Touch with ",
                    },
                    {
                      text: "Eate Digital",
                      manipulation: "highlight",
                    },
                  ]}
                />
              </h3>
              <div className="form-row">
                <div className="col-md-8 form-group subscribebtn">
                  <div className="item_input">
                    <input
                      onKeyUp={(event) => {
                        NewsletterSubscribeKeyPress(event);
                      }}
                      type="email"
                      className="form-control rounded-pill"
                      id="exampleInputEmail1"
                      placeholder="Enter email address"
                      aria-describedby="emailHelp"
                    />
                    <button
                      onClick={() => {
                        NewsletterSubscribe();
                      }}
                      type="button"
                      className="btn ripple_circle scale rounded-circle btn_subscribe bg-orange2"
                    >
                      <i className="tio send"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 ml-auto text-lg-right mt-auto">
            <a
              href="https://www.linkedin.com/company/eate-digital/"
              target="_blank"
              rel="noreferrer"
              className="btn-social c-white color-linkedin border-0 mb-0 mr-2"
            >
              <i className="tio linkedin"></i>
            </a>
            <a
              href="https://www.instagram.com/eatedigital/"
              target="_blank"
              rel="noreferrer"
              className="btn-social c-white color-instagram border-0 mb-0 mr-2"
            >
              <i className="tio instagram"></i>
            </a>
            <a
              href="https://twitter.com/eatedigital"
              target="_blank"
              rel="noreferrer"
              className="btn-social c-white color-twitter border-0 mb-0 mr-2"
            >
              <i className="tio twitter"></i>
            </a>
            <a
              href="https://www.tiktok.com/@eatetv?lang=en"
              target="_blank"
              rel="noreferrer"
              className="btn-social c-white color-tik_tok border-0 mb-0 mr-2"
            >
              <i className="tio tik_tok"></i>
            </a>
          </div>
        </div>
        <div className="dividar"></div>
        <div className="row">
          <div className="col-md-5 col-lg-4">
            <div className="copyright">
              <p>
                © {new Date().getUTCFullYear()}{" "}
                <Link className="nav-link" to="/">
                  Eate Digital Solutions, Inc.
                </Link>{" "}
                All Right Reseved
              </p>
            </div>
          </div>
          <div className="col-md-7 col-lg-8">
            <ul className="nav justify-content-md-end">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={TERMS_OF_USE_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={SUBSCRIPTION_AGREEMENT_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Subscription Agreement
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <EmailConfirmation
        confirmEvent={onConfirmationPressEvent}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <Modal show={newsletter.isLoading && subscribing}>
        <Modal.Body>
          <LoadingGif />
        </Modal.Body>
      </Modal>

      <Modal
        show={showErrorSuccessModal}
        onHide={() => {
          setShowErrorSuccessModal(false);
        }}
      >
        <Modal.Body>{errorSuccessMsg}</Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowErrorSuccessModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    newsletter: state.newsletter,
    jwt: state.jwt,
  };
};

const mapDispatchToProps = {
  subscribe,
  createAdminJwt,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterContact);
