import React from "react";

const Founder = ({ imgUrl, imgAlt, profileUrl, name, position }) => (
  <div className="hg_person">
    <div className={`${!profileUrl ? "no-profile " : ""}avatar🐱`}>
      <img
        className="square-social"
        src={require("../../../Assets/" + imgUrl).default}
        alt={`${imgAlt}`}
      />
      {profileUrl ? (
        <a
          href={`//www.linkedin.com/in/${profileUrl}`}
          target="_blank"
          rel="noreferrer"
          className="social"
        >
          <i className="tio linkedin_square"></i>
        </a>
      ) : (
        ""
      )}
    </div>
    {/*<img src={require('../../../Assets/' + imgUrl).default} alt={`${imgAlt}`} />*/}
    <div className="txt">
      <h4>{name}</h4>
      <p>
        Co-Founder <br />
        {position}
      </p>
    </div>
  </div>
);

export default Founder;
