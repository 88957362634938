import React from "react";

import "./HelpCenterStyles.scss";

import IconTabSection from "../../_Shared/Page/IconTabSection/IconTabSection";
import IconTabContent from "../../_Shared/Page/IconTabSection/IconTabContent";
import Accordion from "../../_Shared/Page/Accordion/Accordion";
import AccordionItem from "../../_Shared/Page/Accordion/AccordionItem";

import { tabContent } from "./Questions";

const HelpCenter = () => {
  return (
    <div className="help-center">
      <div className="help-center-hero row">
        <div class="col-lg-6 col-md-12 p-0">
          <div className="hero-text">
            <h1>
              Welcome to Our
              <br /> Help Center
            </h1>
            <p>
              An evolving knowledge base of information to help you get familiar
              with our platform.
            </p>
            <a
              href="#FAQ"
              className="btn rounded-pill btn_md_primary custom-button smoothScroll"
            >
              <div className="inside_item">
                <span>Get Started</span>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
          <div className="hero-image">
            <img
              src={require("../../../Assets/help-center-hero.png").default}
              alt="Woman on computer"
            />
          </div>
        </div>
      </div>
      <section id="FAQ" className="faq_section shadow-none eate-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-content">
          <IconTabSection
            icons={[
              {
                title: "Platform",
                id: "platform",
                key: "helpCenter1",
                isActive: true,
              },
              {
                title: "Onboarding",
                id: "onboarding",
                key: "helpCenter2",
              },
              {
                title: "Menu",
                id: "menu",
                key: "helpCenter3",
              },
              {
                title: "Ordering",
                id: "ordering",
                key: "helpCenter4",
              },
              {
                title: "Payments",
                id: "payments",
                key: "helpCenter5",
              },
              {
                title: "Data",
                id: "data",
                key: "helpCenter6",
              },
            ]}
          >
            {tabContent.map((t) => (
              <IconTabContent isActive={!!t?.isActive} id={t.id} key={t.id}>
                <div className="container">
                  <div className="block_faq">
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <Accordion id={`${t.columnId}1`}>
                          {t.leftQuestions.map((l, idx) => (
                            <AccordionItem
                              key={`${t.columnId}1_${l.id}`}
                              accordionId={`${t.columnId}1`}
                              itemNumber={l.id}
                              header={l.question}
                              isActive={idx === 0}
                            >
                              <p>{l.answer}</p>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <Accordion id={`${t.columnId}2`}>
                          {t.rightQuestions.map((r) => (
                            <AccordionItem
                              key={`${t.columnId}2_${r.id}`}
                              accordionId={`${t.columnId}2`}
                              itemNumber={r.id}
                              header={r.question}
                            >
                              <p>{r.answer}</p>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </IconTabContent>
            ))}
          </IconTabSection>
        </div>
      </section>
    </div>
  );
};

export default HelpCenter;
