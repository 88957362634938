import React from "react";

import "./CustomButtonStyles.scss";

const CustomButton = ({
  children,
  inverted,
  iconClasses,
  customClasses,
  isLink,
  ...otherProps
}) => {
  const invertedClass = inverted ? "custom-button-inverted " : "";
  const iconClass = iconClasses ? "btn_with_ico " : "";
  const customClass = customClasses ? customClasses : "";
  const classes = invertedClass + iconClass + customClass;

  return isLink ? (
    <a
      className={`${classes} btn rounded-pill btn_md_primary custom-button smoothScroll`}
      {...otherProps}
    >
      <span>{children}</span>
      {iconClasses ? <i className={`${iconClasses}`}></i> : ""}
    </a>
  ) : (
    <button
      className={`${classes} btn rounded-pill btn_md_primary custom-button`}
      {...otherProps}
    >
      <span>{children}</span>
      {iconClasses ? <i className={`${iconClasses}`}></i> : ""}
    </button>
  );
};

export default CustomButton;
