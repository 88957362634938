import React from "react";
import { Switch, Route } from "react-router-dom";
import StaticSite from "./StaticSite/StaticSite";
import "./App.scss";

const App = () => {
  return (
    <Switch>
      <Route path="/" component={StaticSite} />
    </Switch>
  );
};

export default App;
