import React from "react";
import { Link, useHistory } from "react-router-dom";

import CustomButton from "../_Shared/Page/CustomButton/CustomButton";

import "./ComingSoonAndroidStyles.scss";

const ComingSoonAndroid = () => {
  let history = useHistory();

  return (
    <section className="coming-soon-android">
      <div className="container">
        <div className="row">
          <div class="col">
            <div className="android-content">
              <h1>
                Coming
                <br />
                <img
                  src={
                    require("../../Assets/coming-soon-android-header.png")
                      .default
                  }
                  alt="Android Icon"
                />{" "}
                Soon
              </h1>
              <h2>The Eate app for Android is still cooking!</h2>
              <CustomButton
                isLink
                href="https://9bwbz1usafs.typeform.com/to/hbo8ArA0"
                target="_blank"
                rel="noreferrer"
              >
                Sign up for an invite
              </CustomButton>
              <p className="android-nav">
                <a onClick={() => history.goBack()}>Go back</a> or visit our{" "}
                <Link to="/">home page</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComingSoonAndroid;
