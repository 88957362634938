import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./RootReducer";

const composeEnhancers = composeWithDevTools({});

let _store;

const configureStore = () => {
  const middleware = [thunk]; //More configurations can be placed in the array
  _store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );

  return _store;
};

export const getStoreState = () => {
  return _store && _store.getState();
};

export default configureStore;
