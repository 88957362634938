import React from "react";
import { Link } from "react-router-dom";

const DropdownLink = ({ isPlainLink, route, text, ...otherProps }) => {
  return (
    <li className="dropdown-submenu">
      {!isPlainLink ? (
        <Link
          className="dropdown-item dropdown-toggle dropdown_menu d-flex justify-content-between"
          to={route}
          {...otherProps}
        >
          {text}
        </Link>
      ) : (
        <a
          className="dropdown-item dropdown-toggle dropdown_menu d-flex justify-content-between"
          href={route}
          {...otherProps}
        >
          {text}
        </a>
      )}
    </li>
  );
};

export default DropdownLink;
