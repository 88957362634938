import React from "react";

import "./GuestAppStyles.scss";

import RightImageHero from "../../_Shared/Page/RightImageHero/RightImageHero";
import ColoredSquarePhoneSection from "../../_Shared/Page/ColoredSquarePhoneSection/ColoredSquarePhoneSection";

const GuestApp = () => (
  <div class="guest-app">
    <RightImageHero
      vertCenterCol
      beforeTitleText="for"
      beforeTitleHighlight="Guests"
      header={[
        {
          text: "Provide Guests with",
        },
        {
          manipulation: "\n",
        },
        {
          text: "Unique Experiences",
        },
      ]}
      content="Provide your guests with an innovative on-and-off premise application, that allows guest the ability to locate your establishment, browse the menu, order, and pay all through their preferred device - enabling your staff to service more guests with greater accuracy."
      imgUrl="explore-eate-hero.png"
      imgAlt="Various segments of the Eate App"
    />
    <h2 className="guest-app-title">Guest App</h2>
    <ColoredSquarePhoneSection
      header="Get Discovered"
      imgUrl="guest-app-phone-1.png"
      imgAlt="Eate app on a phone"
      content={[
        <ul>
          <li>
            Expand your reach and presence with{" "}
            <span className="text-highlight">
              customized Digital Storefront
            </span>{" "}
            at guests' fingertips
          </li>
          <li>
            Stand out with{" "}
            <span className="text-highlight">direct-to-guest marketing</span> to
            reach even more potential guests
          </li>
        </ul>,
      ]}
      backgroundColor="rgba(253, 112, 40, 0.161)"
      animationNumber="2"
    />
    <ColoredSquarePhoneSection
      header="Manage Bookings"
      imgUrl="guest-app-phone-2.png"
      imgAlt="Eate app on a phone"
      content={[
        <ul>
          <li>
            <span className="text-highlight">Keep guests up-to-date</span> with
            automated waitlists and reservations to reduce no shows
          </li>
          <li>
            Seamless bar tab and check transfers{" "}
            <span className="text-highlight">with separate tipping</span>
          </li>
        </ul>,
      ]}
      backgroundColor="rgba(253, 112, 40, 0.161)"
      animationNumber="3"
      isImgRight
    />
    <ColoredSquarePhoneSection
      header="Accept Orders"
      imgUrl="guest-app-phone-3.png"
      imgAlt="Eate app on a phone"
      content={[
        <ul>
          <li>
            Enable{" "}
            <span className="text-highlight">
              online, mobile, and off-premise ordering
            </span>{" "}
            to increase sales, check size, and tips
          </li>
          <li>
            <span className="text-highlight">
              Plan your inventory and staff needs
            </span>{" "}
            with pre-orders, order ahead, and order trends
          </li>
        </ul>,
      ]}
      backgroundColor="rgba(253, 112, 40, 0.161)"
      animationNumber="4"
    />
    <ColoredSquarePhoneSection
      header="Accept Payments"
      imgUrl="guest-app-phone-4.png"
      imgAlt="Eate app on a phone"
      content={[
        <ul>
          <li>
            Enable digital payment methods for guests to{" "}
            <span className="text-highlight">
              pay for the entire order, or split payment among a group
            </span>
            , increasing check sizes and tips
          </li>
          <li>
            Improve the guest experiences with open tab ordering or
            pay-as-you-go,{" "}
            <span className="text-highlight">
              secured with saved cards on file
            </span>
          </li>
        </ul>,
      ]}
      backgroundColor="rgba(253, 112, 40, 0.161)"
      animationNumber="5"
      isImgRight
    />
  </div>
);

export default GuestApp;
