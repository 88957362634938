import * as types from "./LaunchDarklyActionTypes";
import initialState from "../InitialState";

const launchDarklyReducer = (
  state = initialState.launchDarklyDetails,
  action
) => {
  switch (action.type) {
    case types.FETCH_FEATURE_FLAGS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
        timestamp: new Date(),
      };
    case types.FETCH_FEATURE_FLAGS_FAILED:
      return {
        ...state,
        isLoading: false,
        featureFlags: {},
        error: action.payload,
        timestamp: new Date(),
      };
    case types.FETCH_FEATURE_FLAGS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        featureFlags: action.payload,
        error: null,
        timestamp: new Date(),
      };
    default:
      return state;
  }
};

export default launchDarklyReducer;
