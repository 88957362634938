import * as types from "./LaunchDarklyActionTypes";
import * as launchDarklyService from "./LaunchDarklyServices";

const fetchFeatureFlagsStarted = () => ({
  type: types.FETCH_FEATURE_FLAGS_STARTED,
});

const fetchFeatureFlagsSucceeded = (data) => ({
  type: types.FETCH_FEATURE_FLAGS_SUCCEEDED,
  payload: data,
});

const fetchFeatureFlagsFailed = (error) => ({
  type: types.FETCH_FEATURE_FLAGS_FAILED,
  payload: error,
});

export const fetchFeatureFlags = (role) => (dispatch) => {
  dispatch(fetchFeatureFlagsStarted());
  return launchDarklyService.getFeatureFlags(role).then(
    (response) => {
      dispatch(fetchFeatureFlagsSucceeded({ ...response.data.data }));
    },
    (error) => {
      dispatch(fetchFeatureFlagsFailed(error));
    }
  );
};
