export const CREATE_ADMIN_JWT_STARTED = "CREATE_ADMIN_JWT_STARTED";

export const CREATE_ADMIN_JWT_SUCCEEDED = "CREATE_ADMIN_JWT_SUCCEEDED";

export const CREATE_ADMIN_JWT_FAILED = "CREATE_ADMIN_JWT_FAILED";

export const CREATE_CORE_JWT_STARTED = "CREATE_CORE_JWT_STARTED";

export const CREATE_CORE_JWT_SUCCEEDED = "CREATE_CORE_JWT_SUCCEEDED";

export const CREATE_CORE_JWT_FAILED = "CREATE_CORE_JWT_FAILED";
