import React from "react";

import "./ExploreHosteStyles.scss";

import RightImageHero from "../../_Shared/Page/RightImageHero/RightImageHero";
import Accordion from "../../_Shared/Page/Accordion/Accordion";
import AccordionItem from "../../_Shared/Page/Accordion/AccordionItem";
import IconTabSection from "../../_Shared/Page/IconTabSection/IconTabSection";
import IconTabContent from "../../_Shared/Page/IconTabSection/IconTabContent";
import VideoLibrary from "../../_Shared/Page/VideoLibrary/VideoLibrary";
import VideoLibraryItem from "../../_Shared/Page/VideoLibrary/VideoLibraryItem";
import ToggleSliderSection from "../../_Shared/Page/ToggleSliderSection/ToggleSliderSection";
import ToggleContent from "../../_Shared/Page/ToggleSliderSection/ToggleContent";
import TextStyling from "../../_Shared/Page/TextStyling/TextStyling";

const ExploreHoste = () => (
  <>
    <RightImageHero
      vertCenterCol
      additionalClass="grayGradient"
      beforeTitleText="for"
      beforeTitleHighlight="Restaurants"
      header={[
        {
          text: "Centralize Your",
        },
        {
          manipulation: "\n",
        },
        {
          text: "Revenue Streams",
        },
      ]}
      content="House the technologies that keep your restaurant running all in one place to obtain one view on all revenue streams within your operations."
      imgUrl="explore-hoste-hero.png"
      imgAlt="Various segments of the Hoste App"
    />
    <div id="VideoLibrary" />
    <section className="hoste-video-library">
      <div className="container">
        <ToggleSliderSection
          header="Hoste Video Library"
          summary="Our platform consists of complete front of house and back of house functionality both available on tablet and mobile devices."
          leftLabel="Tablet"
          rightLabel="Mobile"
        >
          <ToggleContent>
            <IconTabSection
              icons={[
                {
                  iconActiveUrl: "user-icon.svg",
                  iconInactiveUrl: "user-icon-dark.svg",
                  title: "Point of Sale",
                  id: "r_t_pointOfSale",
                  key: "hostVideoLibraryTablet1",
                  label: "Coming Soon",
                  disabled: true,
                },
                {
                  iconActiveUrl: "user-icon.svg",
                  iconInactiveUrl: "user-icon-dark.svg",
                  title: "Point of Sale Lite",
                  id: "r_t_pointOfSale",
                  key: "hostVideoLibraryTablet2",
                  label: "Coming Soon",
                  disabled: true,
                },
                {
                  iconActiveUrl: "user-icon.svg",
                  iconInactiveUrl: "user-icon-dark.svg",
                  title: "Bar Point of Sale",
                  id: "r_t_barBointOfSale",
                  key: "hostVideoLibraryTablet3",
                  label: "Coming Soon",
                  disabled: true,
                },
                {
                  iconActiveUrl: "dinner-icon.svg",
                  iconInactiveUrl: "dinner-icon-dark.svg",
                  title: "Kitchen Display System",
                  id: "r_t_kitchenDisplaySystem",
                  key: "hostVideoLibraryTablet4",
                  label: "Coming Soon",
                  disabled: true,
                },
                {
                  iconActiveUrl: "user-icon.svg",
                  iconInactiveUrl: "user-icon-dark.svg",
                  title: "Waitlist & Reservation Management",
                  id: "r_t_waitlist",
                  key: "hostVideoLibraryTablet5",
                  label: "Coming Soon",
                  disabled: true,
                },
              ]}
            ></IconTabSection>
          </ToggleContent>
          <ToggleContent>
            <IconTabSection
              icons={[
                {
                  iconActiveUrl: "user-icon.svg",
                  iconInactiveUrl: "user-icon-dark.svg",
                  title: "Restaurant Manager",
                  id: "r_manager",
                  key: "hostVideoLibrary1",
                  isActive: true,
                },
                {
                  iconActiveUrl: "dinner-icon.svg",
                  iconInactiveUrl: "dinner-icon-dark.svg",
                  title: "Restaurant Server",
                  id: "r_server",
                  key: "hostVideoLibrary2",
                },
                {
                  iconActiveUrl: "user-icon.svg",
                  iconInactiveUrl: "user-icon-dark.svg",
                  title: "Restaurant Host",
                  id: "r_host",
                  key: "hostVideoLibrary3",
                  label: "Coming Soon",
                  disabled: true,
                },
                {
                  iconActiveUrl: "dinner-icon.svg",
                  iconInactiveUrl: "dinner-icon-dark.svg",
                  title: "Restaurant Bar",
                  id: "r_bar",
                  key: "hostVideoLibrary4",
                  label: "Coming Soon",
                  disabled: true,
                },
                {
                  iconActiveUrl: "dinner-icon.svg",
                  iconInactiveUrl: "dinner-icon-dark.svg",
                  title: "Restaurant Kitchen",
                  id: "r_bar",
                  key: "hostVideoLibrary5",
                  label: "Coming Soon",
                  disabled: true,
                },
              ]}
            >
              <IconTabContent isActive id="r_manager">
                <VideoLibrary>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-m-overview.png"
                    header="Overview"
                    videoUrl="https://www.youtube.com/embed/EUQIE1_STo0"
                  >
                    Getting prepared for a shift has never been easier, or more
                    insightful. Check what announcements have been broadcasted
                    to staff, the details from last night's service, and more
                    all from the overview screen!
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-m-task.png"
                    header="Add a Task"
                    videoUrl="https://www.youtube.com/embed/uuxyAK9yh08"
                  >
                    While the side-work was likely planned prior to the start of
                    the shift, something seems to always come up! No worries, as
                    tasks can be announced on-the-fly to any server actively on
                    a shift.
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-m-weather.png"
                    header="Weather"
                    videoUrl="https://www.youtube.com/embed/EHq8z71hpzI"
                  >
                    Do you need to bring in the tables from the patio because of
                    incoming rain? Do the umbrellas need to be open to make
                    diners more comfortable during a particularly sunny service?
                    Conveniently create tasks for servers based on the upcoming
                    forecast, right from your phone.
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-m-stats.png"
                    header="My Stats"
                    videoUrl="https://www.youtube.com/embed/gSQAxCG-lW0"
                  >
                    The Analytics tab will help you understand which of your
                    staff are selling the most, which items are being ordered
                    the most, wait time, average bill size, and more. Use this
                    information to help you drive increased profitability and
                    customer satisfaction!
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-m-announcement.png"
                    header="Post an Announcement"
                    videoUrl="https://www.youtube.com/embed/aOOpk3fN8rs"
                  >
                    Easily tell staff about any upcoming important events
                    without playing the telephone game! By creating a new
                    message in the app, everyone on staff can see announcements
                    in the overview tab right from their device.
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-m-table.png"
                    header="View the Request Queue"
                    videoUrl="https://www.youtube.com/embed/gyxiROORkno"
                  >
                    As the active restaurant manager, it's easy to see the
                    status of all active tables, how each server is doing with
                    them, and where they are in the dining experience.
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-m-schedule.png"
                    header="Scheduling"
                    videoUrl="https://www.youtube.com/embed/2eb9TSuhr2g"
                    label="Coming Soon"
                  >
                    Are you staffed appropriately for the night? Will you see an
                    increase in reservations due to the conference happening
                    nearby, or the big concert/game? Easily add staff to a shift
                    using the scheduling tool to ensure wait times are low and
                    customers are happy!
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-m-dynamic.png"
                    header="Dynamic Pricing"
                    videoUrl="https://www.youtube.com/embed/Xd6BHoBCs6o"
                    label="Coming soon"
                  >
                    Introducing Dynamic Pricing! Ensure you have the right price
                    at any time, and drive increased profitability!
                  </VideoLibraryItem>
                </VideoLibrary>
              </IconTabContent>
              <IconTabContent id="r_server">
                <VideoLibrary>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-s-check-in.png"
                    header="Check In"
                    videoUrl="https://youtube.com/embed/i0j7CZDgY24"
                  >
                    Tired of using the POS system to check-in to your shift?
                    Now, check into your shift with ease, right from your phone.
                    Watch this video to learn how!
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-s-order.png"
                    header="Place an Order"
                    videoUrl="https://www.youtube.com/embed/0BhJIsegE7Y"
                  >
                    With
                    <TextStyling
                      text={[
                        {
                          text: " SmartDining",
                        },
                        {
                          text: "TM",
                          manipulation: "subscript",
                        },
                      ]}
                    />
                    , Diners are empowered to order for themselves right from
                    their phone. But if needed, you can key in an order for each
                    guest too, right from the app!
                  </VideoLibraryItem>
                  <VideoLibraryItem
                    imgUrl="explore-hoste-faq-s-check-out.png"
                    header="Check Out"
                    videoUrl="https://www.youtube.com/embed/AaVtBrsX8Rg"
                  >
                    Before you check-out for the night, you can review your side
                    work, and ensure all your tables are empty or have been
                    transferred to another server. And just as easy as it was to
                    check into your shift, you can also check out of your shift
                    from your phone and take a look at your stats from previous
                    shifts.
                  </VideoLibraryItem>
                </VideoLibrary>
              </IconTabContent>
            </IconTabSection>
          </ToggleContent>
        </ToggleSliderSection>
      </div>
    </section>
    <section id="FAQ" className="faq_section shadow-none hoste-faq">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="title_sections_inner">
              <h2>FAQs</h2>
              <p>
                Commonly asked questions regarding our Hoste platform, which
                enables restaurants transform into
                <TextStyling
                  text={[
                    {
                      text: " SmartDining",
                    },
                    {
                      text: "TM",
                      manipulation: "subscript",
                    },
                  ]}
                />{" "}
                experiences.
              </p>
            </div>
          </div>
        </div>
        <div className="block_faq">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <Accordion id="exploreHosteGeneralFaqCol1">
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol1"
                  itemNumber="1"
                  header="Do I need any hardware?"
                  isActive
                >
                  <p>
                    Hoste runs on any iOS or Android device. Simply purchase a
                    compatible device, or staff can use their personal devices.
                    We recommend cellular-enabled tablets for the kitchen and
                    bar for staff to leverage a larger screen.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol1"
                  itemNumber="2"
                  header="What happens if the Wi-Fi goes down?"
                >
                  <p>
                    No Wi-Fi? No problem! Eate works over Wi-Fi and cellular
                    networks.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol1"
                  itemNumber="3"
                  header="What if my phone dies?"
                >
                  <p>
                    We recommend prudent battery life management. Keep chargers,
                    battery cases, or portable battery packs handy for your
                    staff, but staff should show up for their shift with fully
                    charged phones.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol1"
                  itemNumber="4"
                  header="How does my staff get set up?"
                >
                  <p>
                    Restauranteurs will add their staff members during
                    configuration and a User ID is assigned. Once a staff member
                    is added, they simply log into Hoste with their User ID.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol1"
                  itemNumber="5"
                  header="I have more than one location. How does that work?"
                >
                  <p>
                    Easy! Hoste lets you switch between locations with a tap
                    through your settings panel.
                  </p>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="col-md-6 col-lg-6">
              <Accordion id="exploreHosteGeneralFaqCol2">
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol2"
                  itemNumber="1"
                  header="How can I ensure my staff is working?"
                >
                  <p>
                    Hoste provides insights into how your staff uses the app,
                    including how long tables wait for service and how quickly
                    servers respond to requests from diners.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol2"
                  itemNumber="2"
                  header="Is my data secure?"
                >
                  <p>
                    Yes, Eate Digital leverages Amazon Web Services (AWS), the
                    most flexible and secure cloud computing environment
                    available today to protect your data from prying eyes. We do
                    not sell your data to any third-party.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol2"
                  itemNumber="3"
                  header="Does Eate support wearables?"
                >
                  <p>
                    Not yet. However, introducing Hoste through wearable
                    technologies such as the Apple Watch is on our product
                    roadmap.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreHosteGeneralFaqCol2"
                  itemNumber="4"
                  header="Can I use Eate to dine out myself?"
                >
                  <p>
                    Of course! Users of Hoste can seamlessly switch to Eate
                    through their settings panel when they are not checked into
                    a shift.
                  </p>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="BringYourOwnDevice"
      className="faq_section shadow-none hoste-faq hoste-byod-faq"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="title_sections_inner">
              <h2>Bring Your Own Device (BYOD)</h2>
              <p>What does BYOD mean?</p>
              <p>Eate works on any iOS or Android smartphone or tablet</p>
              <ul>
                <li>
                  Staff can install Eate on their pre-owned personal devices
                </li>
                <li>Restaurants can purchase devices for their staff to use</li>
              </ul>
            </div>
          </div>
          <div className="block_faq col-md-6">
            <Accordion id="exploreHosteByodFaq">
              <AccordionItem
                accordionId="exploreHosteByodFaq"
                itemNumber="1"
                header="Battery Life"
              >
                <p>
                  Restaurants should promote battery life management so staff
                  can service diners uninterrupted
                </p>
                <ul>
                  <li>
                    Keep chargers on hand: lightning (iPhone) and micro-USB
                    (Android)
                  </li>
                  <li>
                    Make portable battery packs or charging cases available
                  </li>
                  <li>
                    Reiterate restaurant policies that staff should only use
                    their phone for Eate during their shifts
                  </li>
                </ul>
              </AccordionItem>
              <AccordionItem
                accordionId="exploreHosteByodFaq"
                itemNumber="2"
                header="Internet Connectivity"
              >
                <p>
                  Eate is a cloud-based software that requires internet
                  connectivity
                </p>
                <ul>
                  <li>
                    Ensure the restaurant is enabled with Wi-Fi or a 5G mobile
                    hotspot
                  </li>
                  <li>
                    Mitigate ‘dead zones’ by installing Wi-Fi extenders or
                    hotspot nodes
                  </li>
                  <li>
                    Create a private, password protected network for staff to
                    use
                  </li>
                </ul>
              </AccordionItem>
              <AccordionItem
                accordionId="exploreHosteByodFaq"
                itemNumber="3"
                header="Reduced Hardware Costs"
              >
                <ul>
                  <li>
                    Restaurants do not lease hardware from Eate Digital, so no
                    recurring hardware fees
                  </li>
                  <li>
                    Staff can use their own devices, or devices purchased by the
                    restaurant
                  </li>
                </ul>
              </AccordionItem>
              <AccordionItem
                accordionId="exploreHosteByodFaq"
                itemNumber="4"
                header="Flexibility"
              >
                <ul>
                  <li>
                    Restaurants are not stuck with aging hardware; upgrade
                    devices at any time
                  </li>
                  <li>Easily scale users with staff increases or decreases</li>
                </ul>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default ExploreHoste;
