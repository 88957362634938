import React from "react";

import TextStyling from "../TextStyling/TextStyling";
import IconBox from "./IconBox";

import "./IconBoxImageSectionStyles.scss";

const IconBoxes = ({ header, boxContent, imageUrl }) => {
  return (
    <section
      className="services_section service_four fitures_Appfitness padding-t-15 icon-boxes-image-section"
      id="Services"
    >
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-10">
            <div className="title_sections">
              <h2 className="font-s-60 font-w-700">
                <TextStyling text={header} />
              </h2>
            </div>
          </div>
        </div>
        <div className="features_bb">
          <div className="row">
            <div className="col-lg-7 margin-t-5 p-0">
              <div className="row icon-box-row">
                {boxContent.map(({ id, ...otherSectionProps }) => (
                  <IconBox key={id} {...otherSectionProps} />
                ))}
              </div>
            </div>
            <div className="col p-0">
              <img
                src={require("../../../../Assets/" + imageUrl).default}
                className="icon-box-supporting-img"
                alt="Eate app on a mobile phone"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IconBoxes;
