import React from "react";
import "./LoadingGif.scss";

const LoadingGif = () => {
  return (
    <div id="loadingGif" className="loadingio-spinner-dual-ring-q5z4a4w3zic">
      <div className="ldio-6i1dtu4x0n">
        <div></div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingGif;
