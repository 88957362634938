export const restaurantTypes = [
  {
    id: 0,
    name: "Please choose a type",
    code: "default",
  },
  {
    id: 1,
    name: "Full Service",
    code: "full_service",
    type: "restaurant",
  },
  {
    id: 2,
    name: "Quick Service",
    code: "quick_service",
    type: "restaurant",
  },
  {
    id: 3,
    name: "Coffee Shop & Cafe",
    code: "cafe",
    type: "restaurant",
  },
  {
    id: 4,
    name: "Food Truck",
    code: "truck",
    type: "restaurant",
  },
  {
    id: 5,
    name: "Bar & Brewery",
    code: "bar",
    type: "restaurant",
  },
  {
    id: 6,
    name: "Fast Casual",
    code: "fast_casual",
    type: "restaurant",
  },
  {
    id: 7,
    name: "Pop Up",
    code: "popup",
    type: "restaurant",
  },
  {
    id: 8,
    name: "Ghost Kitchen",
    code: "ghost",
    type: "restaurant",
  },
  {
    id: 9,
    name: "Fine Dining",
    code: "fine_dine",
    type: "restaurant",
  },
  {
    id: 10,
    name: "Other",
    code: "other",
    type: "restaurant",
  },
];
