import * as types from "./ValidateEmployeeActionTypes";
import initialState from "../InitialState";

const validateAccountReducer = (
  state = initialState.validateEmployeeDetails,
  action
) => {
  switch (action.type) {
    case types.VALIDATE_EMPLOYEE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.VALIDATE_EMPLOYEE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: false,
        error: action.payload,
      };
    case types.VALIDATE_EMPLOYEE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default validateAccountReducer;
