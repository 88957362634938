import React from "react";

import VideoLibraryItem from "./VideoLibraryItem";

import "./VideoLibraryStyles.scss";

const VideoLibrary = ({ children }) => (
  <div className="row video-library">{children}</div>
);

export default VideoLibrary;
