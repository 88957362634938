import apiFactory from "../AdminApiFactory";

const apiGateway = apiFactory();

export const subscribe = (data) => {
  return apiGateway.post(`/newsletter/subscribe/${data}`);
};

export const unsubscribe = (data) => {
  return apiGateway.post(`/newsletter/unsubscribe/${data}`);
};
