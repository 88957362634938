import apiFactory from "../CoreApiFactory";

const apiGateway = apiFactory();

export const getIcons = (type) => {
  if (!!type) {
    return apiGateway.get(`/SvgIcons/All/${type}`);
  }

  return apiGateway.get(`/SvgIcons/All`);
};

export const updateIcon = (data) => {
  return apiGateway.post(`/SvgIcons/Update/${data}`);
};
