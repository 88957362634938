import React from "react";

import "./TextStylingStyles.scss";

const TextStyling = ({ text }) => {
  return text.map((textData, index) => {
    switch (textData.manipulation) {
      case "\n":
        return <br key={`style_break_${index}`} />;
      case "itallic":
        return (
          <i key={`style_itallic_${textData.text.substring(0, 3)}`}>
            {textData.text}
          </i>
        );
      case "bold":
        return (
          <b key={`style_bold_${textData.text.substring(0, 3)}`}>
            {textData.text}
          </b>
        );
      case "underline":
        return (
          <u key={`style_underline_${textData.text.substring(0, 3)}`}>
            {textData.text}
          </u>
        );
      case "highlight":
        return (
          <span
            className="c-orange2"
            key={`style_highlight_${textData.text.substring(0, 3)}`}
          >
            {textData.text}
          </span>
        );
      case "subscript":
        return (
          <span
            className="subscript"
            key={`style_subscript_${textData.text.substring(0, 3)}`}
          >
            {textData.text}
          </span>
        );
      case "icon":
        return (
          <img
            src={require("../../../../Assets/Icons/" + textData.text).default}
            class="text-styling-icon"
            alt=""
          />
        );
      default:
        return (
          <span key={`style_plain_${textData.text.substring(0, 3)}`}>
            {textData.text}
          </span>
        );
    }
  });
};

export default TextStyling;
