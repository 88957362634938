import * as types from "./SvgIconsActionTypes";
import * as svgIconsService from "./SvgIconsServices";

const fetchSvgIconsStarted = () => ({
  type: types.FETCH_SVG_ICONS_STARTED,
});

const fetchSvgIconsSucceeded = (data) => ({
  type: types.FETCH_SVG_ICONS_SUCCEEDED,
  payload: data,
});

const fetchSvgIconsFailed = (error) => ({
  type: types.FETCH_SVG_ICONS_FAILED,
  payload: error,
});

export const fetchSvgIcons = (type) => (dispatch) => {
  dispatch(fetchSvgIconsStarted());
  return svgIconsService.getIcons(type).then(
    (response) => {
      dispatch(fetchSvgIconsSucceeded(response.data.data));
    },
    (error) => {
      dispatch(fetchSvgIconsFailed(error));
    }
  );
};

const updateSvgIconStarted = () => ({
  type: types.UPDATE_SVG_ICON_STARTED,
});

const updateSvgIconSucceeded = (data) => ({
  type: types.UPDATE_SVG_ICON_SUCCEEDED,
  payload: data,
});

const updateSvgIconFailed = (error) => ({
  type: types.UPDATE_SVG_ICON_FAILED,
  payload: error,
});

export const updateSvgIcon = (type) => (dispatch) => {
  dispatch(updateSvgIconStarted());
  return svgIconsService.updateIcon(type).then(
    (response) => {
      dispatch(updateSvgIconSucceeded(response.data.data));
    },
    (error) => {
      dispatch(updateSvgIconFailed(error));
    }
  );
};
