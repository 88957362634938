import React from "react";

const Modal = () => (
  <div
    className="modal mdll_video fade"
    id="mdllVideo"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <button
      type="button"
      className="close bbt_close ripple_circle"
      data-dismiss="modal"
      aria-label="Close"
    >
      <i className="tio clear"></i>
    </button>
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-body">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src=""
              id="video"
              allowscriptaccess="always"
              allow="autoplay"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Modal;
