import React, { useState } from "react";

import SignUp from "./SignUp/SignUp";
import FooterAppSideImage from "./FooterAppSideImage";

const FooterApp = ({
  header,
  showAppDetails,
  phoneImgUrl,
  leftImgs,
  rightImgs,
}) => {
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const LaunchSignUpModal = () => setShowSignUpModal(true);

  return (
    <section className="feature_app feature_demo2 padding-t-12 eate-footer-top">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-10 col-lg-6 text-center">
            <div className="title_sections">
              <h2>{header}</h2>
              {showAppDetails && (
                <div>
                  <div className="eate-app-available-on">
                    <div className="eate-available-details available-active">
                      <p>Available on</p>
                      <img
                        src={
                          require("../../../Assets/Icons/store-app-black-icon.svg")
                            .default
                        }
                        alt="App store icon"
                      />
                    </div>
                    <div className="eate-available-details">
                      <p>Coming soon</p>
                      <img
                        src={
                          require("../../../Assets/Icons/store-play-gray-icon.svg")
                            .default
                        }
                        alt="Google play store icon"
                      />
                    </div>
                  </div>
                  <a
                    className="btn btn_sm_primary bg-orange2 opacity-1 c-white border-0 rounded-pill"
                    href="mailto:sales@eatedigital.com"
                    style={{
                      marginTop: 20,
                    }}
                  >
                    Get Started
                  </a>
                  {/*<button
                    className="btn btn_sm_primary bg-orange2 opacity-1 c-white border-0 rounded-pill"
                    onClick={LaunchSignUpModal}
                  >
                    Get Started
                      </button>*/}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row d-flex">
          <div className="col-md-6 col-lg-4 d-none d-sm-block order-1 order-sm-1 footer-side-imgs">
            {leftImgs.map(({ id, ...otherSectionProps }) => (
              <FooterAppSideImage key={id} {...otherSectionProps} />
            ))}
          </div>
          <div className="col-md-6 col-lg-4 order-3 order-sm-2 aos-init footer-main-img">
            <div className="app_mobile">
              <img
                src={require("../../../Assets/" + phoneImgUrl).default}
                className="apoo"
                alt="Eate app on a mobile phone"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-none d-sm-block order-2 order-sm-3 footer-side-imgs">
            {rightImgs.map(({ id, ...otherSectionProps }) => (
              <FooterAppSideImage key={id} {...otherSectionProps} />
            ))}
          </div>
        </div>
      </div>

      <SignUp
        showSignUpModal={showSignUpModal}
        setShowSignUpModal={setShowSignUpModal}
      />
    </section>
  );
};

export default FooterApp;
