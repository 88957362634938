import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";

import { validateEmail } from "../../../../Helpers/Extensions";

import { connect } from "react-redux";
import { subscribe } from "../../../../Redux/Newsletter/NewsletterActions";
import LoadingGif from "../../LoadingGif";
import { createAdminJwt } from "../../../../Redux/JWT/JwtActions";

import { restaurantTypes } from "./restaurantTypes";
import * as zipCodes from "./zipCodes";
import "./SignUpStyles.scss";

const SignUp = ({
  showSignUpModal,
  setShowSignUpModal,
  subscribe,
  newsletter,
  createAdminJwt,
  jwt,
}) => {
  const history = useHistory();

  const handleClose = () => {
    setShowSignUpModal(false);
  };

  const [initialized, setInitialized] = useState(false);
  const [showErrorSuccessModal, setShowErrorSuccessModal] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState("");
  const [subscribing, setSubscribing] = useState(false);
  const [isEditNotice, setIsEditNotice] = useState(true);

  const handleDemoRedirect = () => {
    history.push("/restaurants/demo");

    handleClose();

    document.getElementById("progressIndicator").click();
  };

  const handleSignUpSubmit = (event) => {
    event.preventDefault();

    const email = document.getElementById("restaurant-email").value;
    const subscriptionType = "waitlist_restaurant_welcome";

    if (validateEmail(email)) {
      const restaurantName = document.getElementById("restaurant-name").value;
      const restaurantType = document.getElementById("restaurant-type").value;
      const restaurantRole = document.getElementById("restaurant-role").value;
      const restaurantStaff = document.getElementById("restaurant-staff-number")
        .value;
      const restaurantZipCode = document.getElementById("restaurant-zip-code")
        .value;
      const restaurantCity = document.getElementById("restaurant-city").value;
      const restaurantState = document.getElementById("restaurant-state").value;

      setSubscribing(true);
      subscribe({
        email: email,
        type: subscriptionType,
        role: restaurantRole,
        zip: restaurantZipCode,
        city: restaurantCity,
        state: restaurantState,
        restaurantType: restaurantType,
        restaurant: restaurantName,
        staffCount: restaurantStaff,
      });
    } else {
      setIsEditNotice(true);
      setErrorSuccessMsg("Please insert a valid email address.");
      setShowErrorSuccessModal(true);
    }
  };

  const handleZipCodeChange = () => {
    const zipCode = document.getElementById("restaurant-zip-code").value;

    if (zipCode.length === 5) {
      const zipCodeData = zipCodes[zipCode];

      if (!!zipCodeData) {
        const city = zipCodeData.city;
        const state = zipCodeData.state;

        document.getElementById("restaurant-city").value = city;
        document.getElementById("restaurant-state").value = state;
      } else {
        clearCityState();
      }
    } else {
      clearCityState();
    }
  };

  const clearCityState = () => {
    document.getElementById("restaurant-city").value = "";
    document.getElementById("restaurant-state").value = "";
  };

  const handleSuccessErrorClose = () => {
    if (!isEditNotice) {
      setShowSignUpModal(false);
    }

    setShowErrorSuccessModal(false);
  };

  useEffect(() => {
    if (!initialized) {
      if (!jwt.admin.bearer) {
        createAdminJwt();
      }

      setInitialized(true);
    }
  }, [initialized, jwt, createAdminJwt]);

  useEffect(() => {
    if (!!newsletter && !newsletter.isLoading && subscribing) {
      if (newsletter.data.code === 200) {
        setErrorSuccessMsg("You have been added to the Eate Digital waitlist!");
      } else {
        setErrorSuccessMsg(
          "Unfortunately, there was an error adding you to the waitlist. Please email hello@eatedigital.com, and we'll be sure to you add you!"
        );
      }

      setShowSignUpModal(false);
      setIsEditNotice(false);
      setShowErrorSuccessModal(true);
      setSubscribing(false);
      document.getElementById("restaurant-email").value = "";
      document.getElementById("restaurant-name").value = "";
      document.getElementById("restaurant-type").options[0].selected = true;
      document.getElementById("restaurant-role").options[0].selected = true;
      document.getElementById("restaurant-staff-number").value = "";
      document.getElementById("restaurant-zip-code").value = "";
      document.getElementById("restaurant-city").value = "";
      document.getElementById("restaurant-state").value = "";
    }
  }, [newsletter, subscribing, setShowSignUpModal]);

  return (
    <>
      <Modal
        show={showSignUpModal}
        onHide={handleClose}
        className="sign-up-modal"
      >
        {newsletter.isLoading && subscribing && (
          <Modal.Body>
            <LoadingGif />
          </Modal.Body>
        )}
        {!newsletter.isLoading && (
          <Modal.Header closeButton>
            <Modal.Title>Get Started</Modal.Title>
          </Modal.Header>
        )}

        {!newsletter.isLoading && (
          <Modal.Body>
            <p className="form-description">
              Tell us a few details so we can get started setting up your
              restaurant on our platform.
            </p>
            <p className="form-description">
              Not quite ready? Book a{" "}
              <span onClick={() => handleDemoRedirect()}>demo</span> today.
            </p>
            <Form onSubmit={handleSignUpSubmit}>
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="ex: name@company.com"
                      id="restaurant-email"
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Restaurant/Group Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Please enter a name"
                      id="restaurant-name"
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Type of Restaurant</Form.Label>
                    <Form.Select
                      aria-label="Please choose a type"
                      id="restaurant-type"
                    >
                      {restaurantTypes.map((r) => {
                        const cssClass = r.id === 0 ? "default-select" : "";
                        const value = r.id === 0 ? "" : r.code;

                        return (
                          <option
                            value={value}
                            className={cssClass}
                            key={`${r.code}`}
                          >
                            {r.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Select Restaurant Role</Form.Label>
                    <Form.Select
                      aria-label="Please choose a role"
                      id="restaurant-role"
                    >
                      <option className="default-select">
                        Please choose a role
                      </option>
                      <option value="owner">Owner</option>
                      <option value="manager">Manager</option>
                      <option value="Staff">Staff</option>
                      <option value="other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div class="col-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Staff Count</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Please enter number of staff"
                      id="restaurant-staff-number"
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      onChange={handleZipCodeChange}
                      type="text"
                      placeholder="Please enter a zip code"
                      id="restaurant-zip-code"
                    />
                  </Form.Group>
                </div>
                <div class="col-3">
                  <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      id="restaurant-city"
                      disabled
                    />
                  </Form.Group>
                </div>
                <div class="col-3">
                  <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      id="restaurant-state"
                      disabled
                    />
                  </Form.Group>
                </div>
              </div>

              <p className="form-disclaimer">
                We value and respect your privacy. By singing up, you agree to
                sign up for Eate Digital’s newsletters and you acknowledge and
                agree to Eate Digital’s{" "}
                <a
                  href="https://docs.eatedigital.com/privacy-policy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </a>
                .
              </p>
              <Button
                variant="primary"
                disabled={newsletter.isLoading}
                type="submit"
                className="restaurant-submit"
              >
                Sign Up
              </Button>
            </Form>
          </Modal.Body>
        )}
      </Modal>

      <Modal
        show={showErrorSuccessModal}
        onHide={handleSuccessErrorClose}
        className="sign-up-error-success-modal"
      >
        <Modal.Body>{errorSuccessMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSuccessErrorClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    newsletter: state.newsletter,
    jwt: state.jwt,
  };
};

const mapDispatchToProps = {
  subscribe,
  createAdminJwt,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
