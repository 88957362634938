import React from "react";

const DropdownLinks = ({ text, children }) => {
  return (
    <li className="nav-item dropdown dropdown-hover">
      <div
        className="nav-link dropdown-toggle dropdown_menu"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {text}
        <div className="icon_arrow">
          <i className="tio chevron_right"></i>
        </div>
      </div>
      <div
        className="dropdown-menu single-drop sm_dropdown"
        aria-labelledby="navbarDropdown"
      >
        <ul className="dropdown_menu_nav">{children}</ul>
      </div>
    </li>
  );
};

export default DropdownLinks;
