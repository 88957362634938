import React from "react";

import "./TextHeroStyles.scss";

const TextHero = ({ backgroundColorCode, children }) => (
  <div
    className="text-hero"
    style={{
      backgroundColor: `${
        backgroundColorCode ? backgroundColorCode : "#ffffff"
      }`,
    }}
  >
    {children}
  </div>
);

export default TextHero;
