import apiFactory from "../CoreApiFactory";

const apiGateway = apiFactory();

export const verifyPhone = (phone) => {
  return apiGateway.get(`/Twilio/Verify/${phone}`);
};

export const checkPhone = (phone, code) => {
  return apiGateway.get(`/Twilio/Check/${phone}/${code}`);
};
