import React from "react";

import HeroSection from "./_Shared/Page/HeroSection/HeroSection";
import IconTabSection from "./_Shared/Page/IconTabSection/IconTabSection";
import IconTabContent from "./_Shared/Page/IconTabSection/IconTabContent";
import ImageTabSection from "./_Shared/Page/ImageTabSection/ImageTabSection";
import IconBoxesImageSection from "./_Shared/Page/IconBoxesImageSection/IconBoxesImageSection";
import ReviewsSection from "./_Shared/Page/ReviewsSection/ReviewsSection";
import SocialMediaSlider from "./_Shared/Page/SocialMediaSlider/SocialMediaSlider";

import CustomButton from "./_Shared/Page/CustomButton/CustomButton";
import TextStyling from "./_Shared/Page/TextStyling/TextStyling";
import DialogBox from "./_Shared/Page/DialogBox/DialogBox";
import ContentSlider from "./_Shared/Page/ContentSlider/ContentSlider";
import MapSlideContent from "./_Shared/Page/ContentSlider/MapSlideContent";
import QuoteSlideContent from "./_Shared/Page/ContentSlider/QuoteSlideContent";
import ArticleSlideContent from "./_Shared/Page/ContentSlider/ArticleSlideContent";

import "./HomePageStyles.scss";

const HomePage = () => (
  <>
    <HeroSection
      isFirst
      content={
        "Streamline your restaurant operations with the industry's leading all-in-one platform, integrating all on-and-off-premise systems to improve margins, increase sales, and enhance guest experiences."
      }
      imageUrl="home-hero.png"
    >
      <span>
        Eate,{" "}
        <span className="c-orange2">
          the
          <br /> All-In-One Platform
        </span>
      </span>
      <CustomButton
        customClasses={"btn_video"}
        data-toggle="modal"
        data-src="https://www.youtube.com/embed/3c_LEcCVakg"
        data-target="#mdllVideo"
      >
        Watch How Eate Works!
      </CustomButton>
    </HeroSection>
    <div className="platform-details-container">
      <h2>All-in-One Platform</h2>
      <div className="platform-details-content">
        <IconTabSection
          icons={[
            {
              title: "All",
              id: "all",
              key: "platform_all",
              isActive: true,
            },
            {
              title: "Bars, Breweries & Wineries",
              id: "bars",
              key: "platform_bar",
            },
            {
              title: "Cafés",
              id: "cafes",
              key: "platform_cafes",
            },
            {
              title: "Ghost Kitchens",
              id: "ghostKitchens",
              key: "platform_ghostKitchens",
            },
            {
              title: "Pop Ups",
              id: "popUps",
              key: "platform_popUps",
            },
            {
              title: "Food Trucks",
              id: "foodTrucks",
              key: "platform_foodTrucks",
            },
          ]}
        >
          <IconTabContent isActive id="all">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3>
                    Eate Digital Covers the Entire{" "}
                    <span className="text-highlight">Restaurant</span>
                  </h3>
                  <img
                    src={require("../Assets/platform_all.jpeg").default}
                    alt="all platform info graphic"
                  />
                </div>
              </div>
            </div>
          </IconTabContent>
          <IconTabContent id="bars">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3>
                    Eate Digital Covers the Entire{" "}
                    <span className="text-highlight">Bar</span>
                  </h3>
                  <img
                    src={require("../Assets/platform_bars.jpeg").default}
                    alt="bar platform info graphic"
                  />
                </div>
              </div>
            </div>
          </IconTabContent>
          <IconTabContent id="cafes">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3>
                    Eate Digital Covers the Entire{" "}
                    <span className="text-highlight">Café</span>
                  </h3>
                  <img
                    src={require("../Assets/platform_cafes.jpeg").default}
                    alt="cafes platform info graphic"
                  />
                </div>
              </div>
            </div>
          </IconTabContent>
          <IconTabContent id="ghostKitchens">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3>
                    Eate Digital Covers the Entire{" "}
                    <span className="text-highlight">Ghost Kitchen</span>
                  </h3>
                  <img
                    src={
                      require("../Assets/platform_ghostkitchen.jpeg").default
                    }
                    alt="ghost kitchen platform info graphic"
                  />
                </div>
              </div>
            </div>
          </IconTabContent>
          <IconTabContent id="popUps">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3>
                    Eate Digital Covers the Entire{" "}
                    <span className="text-highlight">Pop Up</span>
                  </h3>
                  <img
                    src={require("../Assets/platform_popups.jpeg").default}
                    alt="pop up platform info graphic"
                  />
                </div>
              </div>
            </div>
          </IconTabContent>
          <IconTabContent id="foodTrucks">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3>
                    Eate Digital Covers the Entire{" "}
                    <span className="text-highlight">Food Truck</span>
                  </h3>
                  <img
                    src={require("../Assets/platform_foodtrucks.jpeg").default}
                    alt="food truck platform info graphic"
                  />
                </div>
              </div>
            </div>
          </IconTabContent>
        </IconTabSection>
      </div>
    </div>
    <div className="platform-results-container">
      <h2>
        With <span className="text-highlight">Eate</span>, restaurants are
        realizing increases of:{" "}
      </h2>
      <div className="platform-results-content">
        <div className="platform-result">
          <p>+134%</p>
          <p>net sales</p>
        </div>
        <div className="platform-result">
          <p>+46%</p>
          <p>orders</p>
        </div>
        <div className="platform-result">
          <p>+60%</p>
          <p>check size</p>
        </div>
      </div>
      <p className="results-disclaimer">
        Results include Eate Consulting & the Eate Platform
      </p>
    </div>
    <IconBoxesImageSection
      header={[
        {
          text:
            "Every system you need to run your operations, all in one place,  no matter your size!",
        },
      ]}
      boxContent={[
        {
          iconUrl: "smile-icon.svg",
          iconColorClass: "gradient_orange",
          header: "Easy to learn",
          content:
            "An intuitive platform that works the same across every system – if you've learned one, you've learned them all.",
          id: "boxContent_001",
        },
        {
          iconUrl: "document-icon.svg",
          iconColorClass: "gradient_blue",
          header: "Free to the operator",
          content:
            "To help restaurants thrive, we do not charge operators to use Eate.",
          id: "boxContent_002",
        },
        {
          iconUrl: "multi-user-icon.svg",
          iconColorClass: "gradient_green",
          header: "Everything is connected",
          content:
            "An ecosystem built for every type and every size of restaurant.",
          id: "boxContent_003",
        },
        {
          iconUrl: "bar-graph-icon.svg",
          iconColorClass: "gradient_red",
          header: "Improve your value",
          content:
            "Use Eate to increase guest spending and reduce operational costs.",
          id: "boxContent_004",
        },
      ]}
      imageUrl="home-iconBox-phone.png"
    />
    <ContentSlider
      header="Who We Serve"
      sliderClass={"slider-simple"}
      backgroundImgColor={"red"}
      slides={[
        {
          imgUrl: "home_serve-slider_full-service.png",
          alt: "Inside of restaurant",
          title: "Full Service",
          id: "serve-slider_full-service",
        },
        {
          imgUrl: "home_serve-slider_quick-service.png",
          alt: "Tacos on plate",
          title: "Quick Service",
          id: "serve-slider_quick-service",
        },
        {
          imgUrl: "home_serve-slider_coffee.png",
          alt: "Coffee in mugs",
          title: "Coffee Shop & Café",
          id: "serve-slider_coffee",
        },
        {
          imgUrl: "home_serve-slider_pop-up.png",
          alt: "Person greeting in a small restaurant",
          title: "Pop Up Restaurant",
          id: "serve-slider_pop-up",
        },
        {
          imgUrl: "home_serve-slider_food-truck.png",
          alt: "Person greeting in a food truck",
          title: "Food Truck",
          id: "serve-slider_food-truck",
        },
        {
          imgUrl: "home_serve-slider_ghost-kitchen.png",
          alt: "Two kitchen workers",
          title: "Ghost Kitchen",
          id: "serve-slider_ghost-kitchen",
        },
        {
          imgUrl: "home_serve-slider_bar.png",
          alt: "Bar tender pouring drink",
          title: "Bar & Brewery",
          id: "serve-slider_bar",
        },
        {
          imgUrl: "home_serve-slider_fine-dining.png",
          alt: "Small portioned dishes",
          title: "Fine Dining",
          id: "serve-slider_fine-dining",
        },
        {
          imgUrl: "home_serve-slider_fast-casual.png",
          alt: "Burger and fries in basket",
          title: "Fast Casual",
          id: "serve-slider_fast-casual",
        },
        {
          imgUrl: "home_serve-slider_prem-casual.png",
          alt: "Upscale tabled dinner",
          title: "Premium Casual",
          id: "serve-slider_prem-casual",
        },
      ]}
    />
    <div className="restaurant-quotes">
      <ContentSlider
        header="Trusted by all types of restaurant operators!"
        sliderClass={"slider-average"}
        backgroundImgColor={"tan"}
        backgroundSize={"90% 500px"}
        slides={[
          {
            imgUrl: "home_map-slider_moonwalker.png",
            alt: "moonwalker logo",
            id: "map-slider_moonwalker",
            content: (
              <MapSlideContent
                title="Moonwalker Café"
                category="Cafe"
                location="Avondale, Chicago"
              />
            ),
          },
          {
            id: "map-slider_moonwalker_quote",
            content: (
              <QuoteSlideContent
                quote={`"The Eate team has given us white glove service. They are more than just a platform. Their insights have immediately made a difference"`}
                author="Luna"
                authorTitle="Owner & Head Chef"
              />
            ),
            buttonText: "Watch Now",
            buttonVideoLink:
              "https://www.youtube.com/embed/wEyCs4hofwU?si=bQETW7-5ZN76bKwu",
          },
          {
            imgUrl: "home_map-slider_ositos.png",
            alt: "ositos logo",
            id: "map-slider_ositos",
            content: (
              <MapSlideContent
                title="Osito’s Tap"
                category="Bar & Speakeasy Lounge"
                location="Pilsen, Chicago"
              />
            ),
          },
          {
            id: "map-slider_ositos_quote",
            content: (
              <QuoteSlideContent
                quote={`"Eate provides my staff with all that they need to service and instantly creates more operational efficiency for my unit"`}
                author="Mike"
                authorTitle="Owner"
              />
            ),
          },
          {
            imgUrl: "home_map-slider_hectors.png",
            alt: "hectors logo",
            id: "map-slider_hectors",
            content: (
              <MapSlideContent
                title="Hectors Smash Burgers"
                category="Pop Up"
                location="Logan Square, Chicago"
              />
            ),
          },
          {
            id: "map-slider_hectors_quote",
            content: (
              <QuoteSlideContent
                quote={`"As a cook, Eate allows me to share with guests when and where I’ll be hosting my next pop up! They make it easy for guest to order, pick up and go!"`}
                author="Hector"
                authorTitle="Owner & Head Chef"
              />
            ),
            buttonText: "Watch Now",
            buttonVideoLink:
              "https://www.youtube.com/embed/Ga38RtLhHBY?si=1n8edoYE3e3Y9bFx",
          },
          {
            imgUrl: "home_map-slider_pierogi.png",
            alt: "pierogi papi logo",
            id: "map-slider_pierogi",
            content: (
              <MapSlideContent
                title="Pierogi Papi"
                category="Pop Up"
                location="Avondale, Chicago"
              />
            ),
          },
          {
            id: "map-slider_pierogi_quote",
            content: (
              <QuoteSlideContent
                quote={`"I’ve seen and used a lot of different systems, Eate has all that you need to really run an efficient restaurant!"`}
                author="Max"
                authorTitle="Owner & Head Chef"
              />
            ),
          },
        ]}
      />
    </div>
    <div className="eate-articles">
      <ContentSlider
        header="Latest Publications"
        sliderClass={"slider-articles"}
        backgroundImgColor={"blue"}
        backgroundSize={"90% 325px"}
        slides={[
          {
            imgUrl: "home_article-slider_1.png",
            alt: "people in restaurant",
            id: "article-slider_1",
            content: (
              <ArticleSlideContent
                title="What’s the Next Big Opportunity for Restaurants?"
                subtitle="Consolidate technology to maximize revenue (and lower costs)"
                links={[
                  {
                    icon: "linkedin.svg",
                    link:
                      "https://www.linkedin.com/pulse/whats-next-big-opportunity-restaurants-eate-digital/",
                  },
                  {
                    icon: "medium.svg",
                    link:
                      "https://medium.com/eate-digital/whats-the-next-big-opportunity-for-restaurants-963a26d53f04",
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </div>

    <DialogBox />
  </>
);

export default HomePage;
