import React from "react";
import { Link } from "react-router-dom";

const SingleLink = ({ isPlainLink, route, text, ...otherProps }) => {
  return (
    <li className="nav-item">
      {!isPlainLink ? (
        <Link className="nav-link" to={route} {...otherProps}>
          {text}
        </Link>
      ) : (
        <a className="nav-link" href={route} {...otherProps}>
          {text}
        </a>
      )}
    </li>
  );
};

export default SingleLink;
