import * as types from "./TwilioActionTypes";
import * as twilioService from "./TwilioServices";

const verifyNumberStarted = () => ({
  type: types.VERIFY_NUMBER_STARTED,
});

const verifyNumberSucceeded = (data) => ({
  type: types.VERIFY_NUMBER_SUCCEEDED,
  payload: data,
});

const verifyNumberFailed = (error) => ({
  type: types.VERIFY_NUMBER_FAILED,
  payload: error,
});

export const verifyNumber = (phone) => (dispatch) => {
  dispatch(verifyNumberStarted());
  return twilioService.verifyPhone(phone).then(
    (response) => {
      dispatch(verifyNumberSucceeded(response.data));
    },
    (error) => {
      dispatch(verifyNumberFailed(error));
    }
  );
};

const verificationCheckStarted = () => ({
  type: types.VERIFICATION_CHECK_STARTED,
});

const verificationCheckSucceeded = (data) => ({
  type: types.VERIFICATION_CHECK_SUCCEEDED,
  payload: data,
});

const verificationCheckFailed = (error) => ({
  type: types.VERIFICATION_CHECK_FAILED,
  payload: error,
});

export const verificationCheck = (phone, code) => (dispatch) => {
  dispatch(verificationCheckStarted());
  return twilioService.checkPhone(phone, code).then(
    (response) => {
      dispatch(verificationCheckSucceeded(response.data));
    },
    (error) => {
      dispatch(verificationCheckFailed(error));
    }
  );
};
