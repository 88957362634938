import React, { useEffect } from "react";

import TextStyling from "../../_Shared/Page/TextStyling/TextStyling";
import TextHero from "../../_Shared/Page/TextHero/TextHero";

import "./RequestDemoStyles.scss";

const Demo = () => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }, []);

  return (
    <section className="request-eate-demo">
      <TextHero backgroundColorCode="rgb(248, 248, 248)">
        <h1>
          <TextStyling
            text={[
              {
                text: "emoji-waving-hand.svg",
                manipulation: "icon",
              },
              {
                text: "Hi, we are ",
              },
              {
                text: "Eate",
                manipulation: "highlight",
              },
              {
                text: ".",
              },
            ]}
          />
        </h1>
        <p>Get a free demo.</p>
        <p class="demo-description">
          Obtain a customized 15, 30 or 1-hour demo of the solutions that matter
          most to you!{" "}
        </p>
      </TextHero>
      <div className="container">
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/eate-digital?primary_color=f5464e"
        ></div>
      </div>
    </section>
  );
};

export default Demo;
