import React from "react";

const IconTabSection = ({ isActive, id, children }) => (
  <div
    className={`${isActive ? "show active " : ""}tab-pane fade`}
    id={`${id}`}
    role="tabpanel"
    aria-labelledby={`${id}-tab`}
  >
    {children}
  </div>
);

export default IconTabSection;
