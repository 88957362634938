import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchSvgIcons,
  updateSvgIcon,
} from "../Redux/SvgIcons/SvgIconsActions";

import { SvgMapper } from "../Helpers/SvgMapper";

const SvgIconManager = ({ fetchSvgIcons, svgIcons, updateSvgIcon }) => {
  const [initialized, setInitialized] = useState(false);
  const [svgId, setSvgId] = useState(0);
  const [svgString, setSvgString] = useState("");
  const [svgHeight, setSvgHeight] = useState(24);
  const [svgWidth, setSvgWidth] = useState(24);
  const [svgColor, setSvgColor] = useState("");
  const [addSvg, setAddSvg] = useState(false);

  const SetSvg = (svg) => {
    var container = document.getElementById("svgIconContainer");
    container.innerHTML = svg;
    setSvgString(svg);
  };

  const OnAddNewIconClick = () => {
    const svgValue = document.getElementById("newSvgString").value;
    const svgName = document.getElementById("newSvgName").value;

    if (!!svgValue && !!svgName) {
      setAddSvg(true);
      const data = {
        name: svgName.trim(),
        value: Buffer.from(svgValue.trim(), "utf8").toString("base64"),
      };

      updateSvgIcon(JSON.stringify(data));
    } else {
      alert("Invalid name or svg string");
    }
  };

  const OnResetSvgClick = () => {
    const svg = svgIcons.data.find((o) => o.id === svgId).value;
    setSvgColor("");
    setSvgHeight(24);
    setSvgWidth(24);
    SetSvg(svg);
  };

  const OnSvgIconNameClick = (id) => {
    const svg = svgIcons.data.find((o) => o.id === id).value;
    const newSvg = SvgMapper({
      icon: svg,
      rectHeight: svgHeight,
      rectWidth: svgWidth,
      color: svgColor,
    });
    setSvgId(id);
    SetSvg(newSvg);
  };

  const OnSvgIconHeightChange = (e) => {
    setSvgHeight(parseInt(e.target.value));

    if (!!svgString) {
      const newSvg = SvgMapper({
        icon: svgString,
        rectHeight: parseInt(e.target.value),
        rectWidth: svgWidth,
        color: svgColor,
      });
      SetSvg(newSvg);
    }
  };

  const OnSvgIconWidthChange = (e) => {
    setSvgWidth(parseInt(e.target.value));

    if (!!svgString) {
      const newSvg = SvgMapper({
        icon: svgString,
        rectHeight: svgHeight,
        rectWidth: parseInt(e.target.value),
        color: svgColor,
      });

      SetSvg(newSvg);
    }
  };

  const OnSvgIconColorChange = (e) => {
    setSvgColor(e.target.value);

    if (!!svgString) {
      const newSvg = SvgMapper({
        icon: svgString,
        rectHeight: svgHeight,
        rectWidth: svgWidth,
        color: e.target.value,
      });

      SetSvg(newSvg);
    }
  };

  useEffect(() => {
    if (!initialized) {
      fetchSvgIcons(2);
      setInitialized(true);
    }
  }, [initialized, fetchSvgIcons]);

  useEffect(() => {
    if (initialized && addSvg && !!svgIcons.icon) {
      fetchSvgIcons(2);
    }
  }, [initialized, addSvg, svgIcons.icon, fetchSvgIcons]);

  return (
    <div className="row" style={{ paddingLeft: "5%", paddingRight: "5%" }}>
      <div className="col-3" style={{ height: 500, overflowY: "auto" }}>
        {!!svgIcons.data &&
          svgIcons.data.length > 0 &&
          svgIcons.data
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .map((s, idx) => {
              return (
                <div className="row" key={`svgIconDiv_${s.id}`}>
                  <a
                    href="#"
                    onClick={() => {
                      OnSvgIconNameClick(s.id);
                    }}
                    key={`svgIconSpan_${s.id}`}
                  >
                    {s.name}
                  </a>
                </div>
              );
            })}
      </div>
      <div className="col-5">
        <div className="row">
          <div className="col-3">
            <span>Height</span>
            <input
              onChange={OnSvgIconHeightChange}
              type="range"
              min="1"
              max="500"
              step="1"
            />
          </div>
          <div className="col-3">
            <span>Width</span>
            <input
              onChange={OnSvgIconWidthChange}
              type="range"
              min="1"
              max="500"
              step="1"
            />
          </div>
          <div className="col-3">
            <span>Color</span>
            <input
              type="color"
              onChange={OnSvgIconColorChange}
              style={{ width: "80%" }}
            />
          </div>
          <div className="col-3">
            <input type="button" onClick={OnResetSvgClick} value="Reset" />
          </div>
        </div>
        <div className="row" style={{ width: "100%", paddingTop: 60 }}>
          <div className="container" id="svgIconContainer"></div>
        </div>
      </div>

      <div className="col-3">
        <div className="row">
          <h3>Add New Svg</h3>
        </div>

        <div className="row" style={{ paddingTop: 15 }}>
          <span>Name:&nbsp;&nbsp;</span>
          <input id="newSvgName" type="text" style={{ width: 120 }} />
        </div>

        <div className="row" style={{ paddingTop: 15 }}>
          <span>Svg String:&nbsp;&nbsp;</span>
          <textarea id="newSvgString"></textarea>
        </div>

        <div className="row" style={{ paddingTop: 15 }}>
          <input
            type="button"
            onClick={OnAddNewIconClick}
            value="Add New Svg Icon"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    svgIcons: state.svgIcons,
  };
};

const mapDispatchToProps = {
  fetchSvgIcons,
  updateSvgIcon,
};

export default connect(mapStateToProps, mapDispatchToProps)(SvgIconManager);
