import React from "react";

import { Link } from "react-router-dom";

import "./DialogBoxStyles.scss";

const DialogBox = () => (
  <div
    aria-live="polite"
    aria-atomic="true"
    className="d-flex justify-content-center align-items-center dialog-box-pop-up"
  >
    <div
      className="toast toast_demo"
      id="myTost"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      data-animation="true"
      data-autohide="false"
    >
      <div className="toast-body">
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
        >
          <i className="tio clear"></i>
        </button>
        <h5>Hi! 👋</h5>
        <p>
          Let us walk you through a{" "}
          <Link className="smoothScroll" to="/restaurants/demo">
            demo
          </Link>{" "}
          of the platform!
        </p>
      </div>
    </div>
  </div>
);

export default DialogBox;
