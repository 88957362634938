import React from "react";
import { Switch, Route } from "react-router-dom";
import SignIn from "./SignIn";
import Dashboard from "./Dashboard";

const AdminPortalRoute = () => {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route path="/SignIn" component={SignIn} />
      <Route exact path="/Dashboard" component={Dashboard} />
    </Switch>
  );
};

export default AdminPortalRoute;
