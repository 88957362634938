import { Link } from "react-router-dom";

/*{
    id: 0,
    question: "Test",
    answer: (<><a href="test">hi</a> yes</>)
},*/

export const tabContent = [
  {
    id: "platform",
    isActive: true,
    columnId: "helpCenterPlatformCol",
    leftQuestions: [
      {
        id: 1,
        question: "What makes Eate unique?",
        answer:
          "Eate provides all your front of house, back of house, and Guest App technology in an all-in-one platform. However, we know one size does not fit all. We help you determine the right tools for your restaurant to get you started, but Operators can choose to use all, or part, of our ecosystem. As you grow, we grow with you - you are free to add or remove tools as needed, at no cost.",
      },
      {
        id: 2,
        question: "What hardware do I need to use Eate?",
        answer:
          "Our platform works with any compatible iOS, iPadOS, or Android device, be it tablet or mobile. We'll help you decide what hardware you need based on the footprint, volume, staff, and setup of your restaurant.",
      },
      {
        id: 3,
        question: "Does Eate Digital offer any other services?",
        answer: (
          <>
            Yes! Restaurants who join our platform have access to Eate{" "}
            <Link to="/restaurants/consulting">consulting services</Link>. Our
            team works directly with our operators to review restaurant
            financials, menus, and strategy to find insights and recommend
            immediate improvements.
          </>
        ),
      },
      {
        id: 4,
        question: "Will Eate integrate with my current POS system?",
        answer:
          "Eate does not currently integrate with existing POS providers, however, our team will make sure the tranistion to Eate is as smooth as possible.",
      },
      {
        id: 5,
        question: "Is Eate really free for restaurants?",
        answer:
          "Yes! Eate Digital does not charge restaurants any subscription, setup, or transaction fees.",
      },
      {
        id: 6,
        question: "Can I sell merchandise on the Eate platform?",
        answer:
          "Yes, In addition to food and beverages, you can also sell merchandise and track its inventory through your Digital Storefront in the Guest App.",
      },
      {
        id: 7,
        question:
          "Does the Eate platform include first and third-party delivery?",
        answer:
          "Coming Soon! We are working with our partners to incorporate first and third-party delivery orders directly into our Kitchen Display System. ",
      },
    ],
    rightQuestions: [
      {
        id: 1,
        question:
          "Does the Eate platform offer tools to measure staff performance and engagement?",
        answer:
          "We have a set of built-in tools that provides insights into your how your staff works while they are checked into their shift, including sales performance, shift clocking, tasks and sidework, and much more.",
      },
      {
        id: 2,
        question: "Can I pause online guest ordering if volumes are too high?",
        answer:
          "Yes, you can pause online guest ordering and indicate a time to automatically reopen while you continue taking in-person orders.",
      },
      {
        id: 3,
        question: "I have more than one location. How does that work?",
        answer:
          "Eate helps you manage multiple locations and sales data, all in one place. Each location will be given its own Digital Storefront on the Guest App and appear separately to guests. You can add additional locations at no cost.",
      },
      {
        id: 4,
        question: "Does Eate Digital white label the platform?",
        answer:
          "If you are interested in white lableing the Eate platform, please reach out to our team so we can learn more about your needs so we can provide a custom quote.",
      },
      {
        id: 5,
        question: "What will my guests experience?",
        answer:
          "You control how your guests interact with you. With Eate, you can service your guests as you traditionally do today, or you can provide your guests with a Guest App, making it easy for them to discover, order, and pay from anywhere.",
      },
      {
        id: 6,
        question: "Is the Guest App available on both iOS and Android",
        answer:
          "Eate is currently available on iOS in the Apple App Store (coming soon to Android in the Google Play Store).",
      },
    ],
  },
  {
    id: "onboarding",
    columnId: "helpCenterOnboardingCol",
    leftQuestions: [
      {
        id: 1,
        question: "How do I get my restaurant on Eate?",
        answer: (
          <>
            Easy! Fill out this <a href="#joinWaitlist">form</a> to provide your
            information and a member of our team will reach out to you with next
            steps.
          </>
        ),
      },
      {
        id: 2,
        question: "How do I up-skill my staff on Eate to service guests?",
        answer:
          "We make platform tutorials, tool tips, and training videos available to you and your staff, as well on-site training. We also provide you and your staff with communication guides to help communicate our platform and its beneifts to your guests.",
      },
      {
        id: 3,
        question: "How involved is the onboarding process?",
        answer:
          "We know you’re busy, so we keep things simple. We handle all aspects of your onboarding, from setting up your restaurant and your staff on the platform, to training everyone involved. Easy-to-follow steps of what’s required to get you up and running quickly are made available through our mobile app. ",
      },
    ],
    rightQuestions: [
      {
        id: 1,
        question: "How can I explain the Guest App to my guests?",
        answer:
          "We help you every step of the way. During the onboarding process, we provide quick tutorial videos showcasing what your guests will see through the Guest App, and provide you with communication guides, QR codes, and training for how to communicate Eate to your guests.",
      },
      {
        id: 2,
        question: "What if I have a question about the Eate platform?",
        answer: (
          <>
            We're here to help! You can contact our Customer Support team
            through our mobile app and by{" "}
            <a href="mailto:customersupport@eatedigital.com">email</a>.
          </>
        ),
      },
    ],
  },
  {
    id: "menu",
    columnId: "helpCenterMenuCol",
    leftQuestions: [
      {
        id: 1,
        question: "How quickly will guests see updates to the menu?",
        answer:
          "Immediately! Updating menus, menu items, item availability, and pricing will appear in the Guest App - and on your staff's handhelds - as soon as you apply and save your changes.",
      },
      {
        id: 2,
        question: "Can I make temporary price changes on the digital menus?",
        answer:
          "Yes, you can update pricing or apply a discounted price to each item on your menu. Updated prices appear on your digital menus in the Guest App, in real time.",
      },
    ],
    rightQuestions: [
      {
        id: 1,
        question: "Can I make certain menus available only at specific times?",
        answer:
          "Yes, you can set any menu to automatically appear for guests on specific days, or at specific times.",
      },
      {
        id: 2,
        question: "Can I manage my inventory?",
        answer:
          "Yes, you can manage the inventory for each item on your menu in your Menu Management settings.",
      },
    ],
  },
  {
    id: "ordering",
    columnId: "helpCenterOrderingCol",
    leftQuestions: [
      {
        id: 1,
        question: "Can I turn off guest ordering through the Guest App?",
        answer:
          "Yes, you can turn guest ordering through the Guest App on or off at any time. Your staff can continue servicing guests as they do today, placing orders through their handhelds or POS.",
      },
      {
        id: 2,
        question: "Can my guests place orders without the Guest App?",
        answer:
          "Yes, your staff can take and place orders through their handhelds; no Guest App required. ",
      },
    ],
    rightQuestions: [
      {
        id: 1,
        question:
          "Can my guests customize items when placing orders through the Guest App?",
        answer:
          "Yes! Any Allergies, Add-Ons, Modifications, and Temperature Customizations (e.g. Medium Rare meat, Over Easy eggs) you assign to your menu items are available to your guests to customize their order through the Guest App.",
      },
      {
        id: 2,
        question: "Can my guests order alcohol through the Guest App?",
        answer:
          "For full service restaurants, guests can order alcohol through the Guest App only after your staff has confirmed their age with a valid ID. You can further restrict alohol ordering by limiting the number of alcoholic drinks per order, and how much time guests must wait before they can place another alcoholic drink order.",
      },
    ],
  },
  {
    id: "payments",
    columnId: "helpCenterPaymentsCol",
    leftQuestions: [
      {
        id: 1,
        question: "What methods of payment can my guests use?",
        answer:
          "Your guests can pay through the Guest App with a saved card on file or digital payment method such as Apple Pay, or can tap, dip, or swipe their card or phone through your staff's handhelds or POS.",
      },
      {
        id: 2,
        question: "Can I provide Promos or Discounts to my guests?",
        answer:
          "Yes, you can create promotion and discount codes that your staff can apply to guest orders from their handhelds, or that guests can apply to their order in the Guest App.",
      },
    ],
    rightQuestions: [
      {
        id: 1,
        question: "Can my guests walk out without paying?",
        answer:
          "No! Depending on your restaurant setup, you can require guests to pay for their meals prior to submitting their order (e.g. quick service), or for full service, we pre-authorize the guest's credit or debit card on file in the Guest App before they can place an order. Regardless of your restaurant setup, you can process guest payments on your handhelds, just as you do today. ",
      },
      {
        id: 2,
        question: "What is the credit or debit card transaction fee? ",
        answer:
          "Transaction fees are determined by our payment processor partner and vary by payment type (In-Person, Card on File, Digital).",
      },
    ],
  },
  {
    id: "data",
    columnId: "helpCenterDataCol",
    leftQuestions: [
      {
        id: 1,
        question: "Does Eate Digital provide guest data to restaurants?",
        answer:
          "Data transparency is important to us, and our users. When guests opt-in to sharing their data with you, we will provide you with insightful information on your guests and allow for direct communication.",
      },
    ],
    rightQuestions: [
      {
        id: 1,
        question: "How secure is my information?",
        answer:
          "We check our software against serveral databases and programs every day, and make rapid updates to protect you from increasingly sophisticated attacks. We leverage Amazon Web Services (AWS), the most flexible and secure cloud computing environment available today to protect your data from unwanted eyes, and we can provide evidence of our data supply chain.",
      },
    ],
  },
];
