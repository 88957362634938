import React from "react";

const IconTab = ({
  iconActiveUrl,
  iconInactiveUrl,
  title,
  id,
  isActive,
  label,
  disabled,
}) => (
  <li
    className={`${disabled ? "disabled-icon-tab " : ""}nav-item`}
    role="presentation"
  >
    {label ? <span className="label-icon-tab">{label}</span> : ""}
    <a
      className={`${isActive ? "active " : ""}nav-link`}
      id={`${id}-tab`}
      data-toggle="pill"
      href={`#${id}`}
      role="tab"
      aria-controls={`${id}`}
      aria-selected={`${isActive ? "true" : "false"}`}
    >
      {(!!iconActiveUrl || !!iconInactiveUrl) && (
        <div className="icon">
          {!!iconActiveUrl && (
            <img
              className="icon-tab-active-icon"
              src={require("../../../../Assets/Icons/" + iconActiveUrl).default}
              alt="tab icon"
            />
          )}
          {!!iconInactiveUrl && (
            <img
              className="icon-tab-inactive-icon"
              src={
                require("../../../../Assets/Icons/" + iconInactiveUrl).default
              }
              alt="tab icon"
            />
          )}
        </div>
      )}
      <h3>{title}</h3>
      <div className="prog"></div>
    </a>
  </li>
);

export default IconTab;
