import React from "react";
import { Modal, Button } from "react-bootstrap";

const EmailConfirmation = ({ showModal, setShowModal, confirmEvent }) => {
  const handleClose = () => setShowModal(false);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        By confirming, you agree to Eate Digital's{" "}
        <a
          href="https://docs.eatedigital.com/privacy-policy.html"
          rel="noreferrer"
          target="_blank"
        >
          privacy policy
        </a>
        , and subscribing to Eate Digital's newsletter.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={confirmEvent}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailConfirmation;
