import React from "react";

const FooterAppSideImage = ({ imgUrl, alt, containerClass }) => {
  return (
    <div className={`item_box ${containerClass}`}>
      <div className="img_bbo">
        <img
          src={require("../../../Assets/" + imgUrl).default}
          alt={`${alt}`}
        />
      </div>
    </div>
  );
};

export default FooterAppSideImage;
