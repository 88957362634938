import React from "react";

const AccordionItem = ({
  accordionId,
  itemNumber,
  header,
  children,
  isActive,
}) => (
  <div className="card border-1">
    <div
      className={`${isActive ? "active " : ""}card-header`}
      id={`${accordionId}_heading${itemNumber}`}
    >
      <h3 className="mb-0">
        <button
          className="btn btn-link"
          type="button"
          data-toggle="collapse"
          data-target={`#${accordionId}_collapse${itemNumber}`}
          aria-expanded={`${isActive ? "true" : "false"}`}
          aria-controls={`${accordionId}_collapse${itemNumber}`}
        >
          {header}
        </button>
      </h3>
    </div>

    <div
      id={`${accordionId}_collapse${itemNumber}`}
      className={`${isActive ? "show " : ""}collapse`}
      aria-labelledby={`${accordionId}_heading${itemNumber}`}
      data-parent={`#${accordionId}`}
    >
      <div className="card-body">{children}</div>
    </div>
  </div>
);

export default AccordionItem;
