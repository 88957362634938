const initialState = {
  newsletterDetails: {
    isLoading: false,
    data: {},
    error: null,
  },
  jwtDetails: {
    isLoading: false,
    admin: {
      basic: Buffer.from(
        `${process.env.REACT_APP_EATE_ADMIN_API_CLIENT_ID}:${process.env.REACT_APP_EATE_ADMIN_API_CLIENT_SECRET}`,
        "utf8"
      ).toString("base64"),
      bearer: null,
    },
    core: {
      basic: Buffer.from(
        `${process.env.REACT_APP_CORE_API_CLIENT_ID}:${process.env.REACT_APP_CORE_API_CLIENT_SECRET}`,
        "utf8"
      ).toString("base64"),
      bearer: null,
    },
    error: null,
  },
  employeeDetails: {
    isLoading: false,
    data: null,
    error: null,
  },
  launchDarklyDetails: {
    isLoading: false,
    featureFlags: {},
    error: null,
    timestamp: new Date(),
  },
  svgIconsDetails: {
    isLoading: false,
    data: {},
    icon: null,
    error: null,
  },
  twilioDetails: {
    isLoading: false,
    verify: null,
    check: null,
    error: null,
  },
  validateEmployeeDetails: {
    isLoading: false,
    data: false,
    error: null,
  },
};

export default initialState;
