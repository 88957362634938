import React from "react";

const IconBox = ({ iconUrl, iconColorClass, header, content }) => (
  <div className="col-md-6 col-lg-6 item iconBox">
    <div
      className="items_serv sevice_block aos-init"
      data-aos="fade-up"
      data-aos-delay="0"
    >
      <div className={`icon--top ${iconColorClass}`}>
        <img
          src={require("../../../../Assets/Icons/" + iconUrl).default}
          alt="icon"
        />
      </div>
      <div className="txt">
        <h3>{header}</h3>
        <p>{content}</p>
      </div>
    </div>
  </div>
);

export default IconBox;
