import React from "react";

import IconTab from "./IconTab";

import "./IconTabSectionStyles.scss";

const IconTabSection = ({ header, icons, children }) => (
  <section className="section__showcase icon-tabs">
    <div className="container p-0">
      {!!header ? (
        <div className="row">
          <div className="col-lg-5">
            <div className="title_sections_inner margin-b-5">
              <h2>{header}</h2>
            </div>
          </div>
        </div>
      ) : null}
      <div className="block__tab">
        <ul className="nav nav-pills" role="tablist">
          {icons.map(({ key, ...otherSectionProps }) => (
            <IconTab key={key} {...otherSectionProps} />
          ))}
        </ul>

        <div className="tab-content">{children}</div>
      </div>
    </div>
  </section>
);

export default IconTabSection;
