import React, { useEffect } from "react";

import TextStyling from "../../_Shared/Page/TextStyling/TextStyling";
import TextHero from "../../_Shared/Page/TextHero/TextHero";

import "./ConsultingStyles.scss";

const Consulting = () => {
  return (
    <section className="restaurant-consulting">
      <TextHero>
        <h1>
          <TextStyling
            text={[
              {
                text: "Eate ",
                manipulation: "highlight",
              },
              {
                text: "Digital Consulting",
              },
            ]}
          />
        </h1>
        <p>
          Our team of former consultants, financial services professionals, food
          service professionals, and technologists bring a data-driven hands-on
          approach to increasing top and bottom-line results for your unit.
        </p>
      </TextHero>
      <div className="consult-stats-container">
        <div className="consult-stat">
          <p>150+</p>
          <p>years of experience</p>
        </div>
        <div className="consult-stat">
          <p>20+</p>
          <p>companies worked for</p>
        </div>
      </div>
      <div class="consult-img">
        <img
          src={require("../../../Assets/consulting-main.png").default}
          alt="Eate consulting"
        />
      </div>
      <div className="consult-services-container">
        <div className="services-header">
          <h2>Unlock your full potential</h2>
          <p>Our team conducts and provides the following</p>
        </div>
        <div className="consult-services">
          <div class="consult-service">
            <div class="consult-img">
              <img
                src={
                  require("../../../Assets/consulting-service-1.png").default
                }
                alt="Woman sitting on money stack"
              />
            </div>
            <h3>Audit</h3>
            <p>Operations and P&L</p>
            <p>Menu, Inventory and Overhead</p>
            <h3>Strategy</h3>
            <p>Actionable Recommendations</p>
          </div>
          <div class="consult-service">
            <div class="consult-img">
              <img
                src={
                  require("../../../Assets/consulting-service-2.png").default
                }
                alt="Woman sitting on money stack"
              />
            </div>
            <h3>Analysis</h3>
            <p>Key Findings</p>
            <p>In-Depth Insights</p>
            <h3>Research</h3>
            <p>Market Peer Comparisons</p>
          </div>
        </div>
      </div>
      <div className="consult-connect">
        <h2>Let's connect</h2>
        <a href="mailto:consulting@eatedigital.com">Contact us</a>
      </div>
      <div className="consult-clients-container">
        <div className="consult-client">
          <h2>Client snapshot</h2>
          <div className="client-info">
            <img
              src={require("../../../Assets/consulting-client-1.png").default}
              alt="Moonwalker Cafe logo"
            />
            <p>Moonwalker Cafe</p>
          </div>
        </div>
        <div className="consult-client-quote">
          <div className="client-quote">
            <img
              src={require("../../../Assets/consulting-quote.png").default}
              alt="Quote icon"
            />
            <p>
              "The Eate team has given us white glove service. They are more
              than just a platform. Their insights have immediately made a
              difference"
            </p>
            <p>Luna</p>
            <p>Owner & Head Chef</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Consulting;
