import React, { useEffect } from "react";
import { connect } from "react-redux";
import SvgIconManager from "./SvgIconManager";

const Dashboard = ({ employee, history }) => {
  document.body.className = "";

  useEffect(() => {
    if (!employee || !employee.data) {
      history.push("/");
    }
  }, [employee, history]);

  return (
    <div>
      <h1>{`${employee.data.firstName}, Welcome To Your Eate Digital Admin Dashboard`}</h1>
      <SvgIconManager />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    employee: state.employee,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
