import React, { useRef } from "react";

import IframeResizer from "iframe-resizer-react";

import "./TheDishStyles.scss";

const TheDish = () => {
  const iframeRef = useRef(null);

  return (
    <section className="the-dish">
      <div className="container">
        <IframeResizer
          forwardRef={iframeRef}
          heightCalculationMethod="lowestElement"
          inPageLinks
          src="https://bugle-cranberry-m7bx.squarespace.com/the-dish"
          checkOrigin={["https://bugle-cranberry-m7bx.squarespace.com"]}
          style={{ width: "1px", minWidth: "100%" }}
        />
      </div>
    </section>
  );
};

export default TheDish;
