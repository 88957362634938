import * as types from "./NewsletterActionTypes";
import initialState from "../InitialState";

const newsletterReducer = (state = initialState.newsletterDetails, action) => {
  switch (action.type) {
    case types.POST_SUBSCRIBE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.POST_SUBSCRIBE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        error: action.payload,
      };
    case types.POST_SUBSCRIBE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case types.POST_UNSUBSCRIBE_STARTED:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case types.POST_UNSUBSCRIBE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        error: action.payload,
      };
    case types.POST_UNSUBSCRIBE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default newsletterReducer;
