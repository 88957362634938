import PropTypes from "prop-types";

export function SvgMapper({
  icon,
  color,
  borderRadius,
  rectHeight,
  rectWidth,
}) {
  const newRadius = borderRadius ?? 0;

  let iconString = icon;

  if (!!iconString) {
    if (!!color) {
      iconString = ColorSvg(color, `fill="`, iconString);
      iconString = ColorSvg(color, `stroke="`, iconString);
    }

    if (borderRadius !== undefined) {
      iconString = BorderRadius(newRadius, `rx="`, iconString);
    }

    if (rectHeight !== undefined) {
      iconString = Height(rectHeight, `height="`, iconString);
    }

    if (rectWidth !== undefined) {
      iconString = Width(rectWidth, `width="`, iconString);
    }
  }

  return iconString;
}

SvgMapper.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  borderRadius: PropTypes.any,
  rectHeight: PropTypes.any,
  rectWidth: PropTypes.any,
};

function RectMapping(tag, value, searchStr, str) {
  let mainString = str;

  if (str.includes("<svg")) {
    let startIndex = str.indexOf("<svg");
    let endIndex = str.indexOf("/>", startIndex) + 2;

    let tmpSearchStart = str.substring(startIndex, endIndex);
    let iconString = tmpSearchStart;

    let searchStrLen = searchStr.length;

    if (searchStrLen === 0) {
      return [];
    }
    startIndex = 0;
    let index;

    while ((index = tmpSearchStart.indexOf(searchStr, startIndex)) > -1) {
      const tmpStr = tmpSearchStart.substring(index, index + searchStr.length);

      const newIdx = tmpSearchStart.indexOf(`"`, index + tmpStr.length + 1);
      const newString = tmpSearchStart.substring(index, newIdx + 1);

      iconString = iconString.replace(newString, `${tag}="${value}"`);

      startIndex = index + searchStrLen;
    }

    mainString = mainString.replace(tmpSearchStart, iconString);
  }

  return mainString;
}

function BorderRadius(radius, searchStr, str) {
  return RectMapping("rx", radius, searchStr, str);
}

function Height(height, searchStr, str) {
  let mainString = RectMapping("height", height, searchStr, str);

  if (mainString.includes("viewBox")) {
    const viewBoxString = `viewBox="`;

    let startIndex = mainString.indexOf(viewBoxString) + viewBoxString.length;
    let endIndex = mainString.indexOf('"', startIndex);

    const tmpViewBox = mainString.substring(startIndex, endIndex);
    let arrViewBox = tmpViewBox.split(` `);
    arrViewBox[2] = height.toString();

    mainString = mainString.replace(
      `${viewBoxString}${tmpViewBox}"`,
      `${viewBoxString}${arrViewBox.join(" ")}"`
    );
  }

  return mainString;
}

function Width(width, searchStr, str) {
  let mainString = RectMapping("width", width, searchStr, str);

  if (mainString.includes("viewBox") && mainString.includes("<image")) {
    const viewBoxString = `viewBox="`;

    let startIndex = mainString.indexOf(viewBoxString) + viewBoxString.length;
    let endIndex = mainString.indexOf('"', startIndex);

    const tmpViewBox = mainString.substring(startIndex, endIndex);
    let arrViewBox = tmpViewBox.split(` `);
    arrViewBox[3] = width.toString();

    mainString = mainString.replace(
      `${viewBoxString}${tmpViewBox}"`,
      `${viewBoxString}${arrViewBox.join(" ")}"`
    );
  }

  return mainString;
}

function ColorSvg(color, searchStr, str) {
  let colorString = str;

  let searchStrLen = searchStr.length;

  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  let index;

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    const tmpStr = str.substring(index, index + searchStr.length);
    const newIdx = str.indexOf(`"`, index + tmpStr.length + 1);
    const newString = str.substring(index, newIdx + 1);

    const tmpColor = newString
      .substring(newString.indexOf(`"`) + 1, newString.length - 1)
      .trim();

    if (tmpColor !== "none") {
      colorString = colorString.replace(tmpColor, color);
    }

    startIndex = index + searchStrLen;
  }
  return colorString;
}
