import React from "react";

import ToggleSliderSection from "../../../_Shared/Page/ToggleSliderSection/ToggleSliderSection";
import ToggleContent from "../../../_Shared/Page/ToggleSliderSection/ToggleContent";

import ColoredSquarePhoneSection from "../../../_Shared/Page/ColoredSquarePhoneSection/ColoredSquarePhoneSection";

import "../PlatformStyles.scss";
import "./ExploreStyles.scss";

const Explore = () => (
  <div class="restaurant-platform">
    <section
      className="demo_1 demo__charity demo__software restaurant-platform-hero"
      id="About"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-5">
            <div className="banner_title">
              <h1>Simplify your operations, by using one central platform.</h1>
              <p>
                Get our full suite of front and back of house technologies. Take
                commission-free orders from anywhere, step up your marketing,
                optimize operations, and maximize profits – no matter your size!
              </p>
              <a
                href="#learnMore"
                className="btn btn_lg_primary bg-blue c-white smoothScroll"
              >
                <div className="inside_item">
                  <span>Learn More</span>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="element_ui">
              <img
                src={
                  require("../../../../Assets/restaurant-explore-hero.png")
                    .default
                }
                alt="Queue card in Eate app"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="learnMore">
      <ToggleSliderSection
        leftHeader="Lite Platform"
        leftSummary="For those that are on the move or don't have table side service, but aim to streamline operations and reduce lines."
        rightHeader="Premium Platform"
        rightSummary="For those that have traditional table side service, aim to streamline operations and provide guests with unique experiences."
        leftLabel="Lite"
        rightLabel="Premium"
        useConditionalText
      >
        <ToggleContent>
          <section className="restaurant-explore-lite">
            <ColoredSquarePhoneSection
              header="Point of Sale"
              imgUrl="restaurant-explore-lite-tablet-1.png"
              imgAlt="Eate app on a tablet"
              isHorizontal
              content={[
                <ul>
                  <li>
                    <span className="text-highlight">
                      Centralize all your sales channels
                    </span>{" "}
                    through a single, easy to use platform
                  </li>
                  <li>
                    Turn any mobile device into a{" "}
                    <span className="text-highlight">
                      handheld POS at no cost
                    </span>
                  </li>
                  <li>
                    Keep{" "}
                    <span className="text-highlight">100% of your profits</span>{" "}
                    on all your sales
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="2"
            />
            <ColoredSquarePhoneSection
              header="Kitchen Display System"
              imgUrl="restaurant-explore-lite-tablet-2.png"
              imgAlt="Eate app on a tablet"
              isHorizontal
              content={[
                <ul>
                  <li>
                    View orders from{" "}
                    <span className="text-highlight">all channels</span> on one
                    screen
                  </li>
                  <li>
                    Accurately fulfill orders{" "}
                    <span className="text-highlight">from any device</span>
                  </li>
                  <li>
                    <span className="text-highlight">No hardware fees</span>
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="3"
              isImgRight
            />
            <ColoredSquarePhoneSection
              header="Online Ordering"
              imgUrl="restaurant-explore-lite-laptop-1.png"
              imgAlt="Eate app on a laptop"
              isHorizontal
              content={[
                <ul>
                  <li>
                    <span className="text-highlight">
                      Accept and manage orders
                    </span>{" "}
                    from any device, whether it's through the web or app
                  </li>
                  <li>
                    <span className="text-highlight">Promote</span> your branded
                    digital storefront directly to your guests
                  </li>
                  <li>
                    Keep{" "}
                    <span className="text-highlight">100% of your profits</span>{" "}
                    on all your online sales
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="4"
            />
            <ColoredSquarePhoneSection
              header="Customer Relationship Management"
              imgUrl="restaurant-explore-lite-phone-1.png"
              imgAlt="Eate app on a mobile phone"
              content={[
                <ul>
                  <li>
                    Know <span className="text-highlight">'why'</span> your
                    guest visit
                  </li>
                  <li>
                    <span className="text-highlight">
                      Direct promotional communication
                    </span>{" "}
                    with your guests
                  </li>
                  <li>
                    <span className="text-highlight">
                      Increase lifetime value
                    </span>{" "}
                    and prioritize the right guests
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="2"
              isImgRight
            />
            <ColoredSquarePhoneSection
              header="Team Tools"
              imgUrl="restaurant-explore-lite-phone-2.png"
              imgAlt="Eate app on a mobile phone"
              content={[
                <ul>
                  <li>
                    <span className="text-highlight">
                      Maximize profitability
                    </span>{" "}
                    and labor costs with daily insights
                  </li>
                  <li>
                    <span className="text-highlight">
                      Automated, streamlined communication
                    </span>{" "}
                    across all restaurant functions
                  </li>
                  <li>
                    <span className="text-highlight">
                      Receive actionable insights
                    </span>{" "}
                    to make better decisions every day
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="3"
            />
          </section>
        </ToggleContent>
        <ToggleContent>
          <section className="restaurant-explore-premium">
            <ColoredSquarePhoneSection
              header="Point of Sale"
              imgUrl="restaurant-explore-prem-tablet-1.png"
              imgAlt="Eate app on a tablet"
              isHorizontal
              content={[
                <ul>
                  <li>
                    <span className="text-highlight">
                      Centralize all your sales channels
                    </span>{" "}
                    through a single, easy to use platform
                  </li>
                  <li>
                    Turn any mobile device into a{" "}
                    <span className="text-highlight">
                      handheld POS at no cost
                    </span>
                  </li>
                  <li>
                    Keep{" "}
                    <span className="text-highlight">100% of your profits</span>{" "}
                    on all your sales
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="2"
            />
            <ColoredSquarePhoneSection
              header="Bar Point of Sale"
              imgUrl="restaurant-explore-prem-tablet-4.png"
              imgAlt="Eate app on a tablet"
              isHorizontal
              content={[
                <ul>
                  <li>
                    <span className="text-highlight">Easy-to-use</span> for{" "}
                    <span className="text-highlight">
                      fast modifications, reordering,
                    </span>{" "}
                    and{" "}
                    <span className="text-highlight">inventory tracking</span>{" "}
                  </li>
                  <li>
                    With pre-authorization and cards on file a{" "}
                    <span className="text-highlight">
                      never worry about guest running out on their tab
                    </span>
                  </li>
                  <li>
                    Keep{" "}
                    <span className="text-highlight">
                      Easily manage orders from a full house
                    </span>{" "}
                    across bar seats, tables, and guests standing nearby
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="3"
              isImgRight
            />
            <ColoredSquarePhoneSection
              header="Online Ordering"
              imgUrl="restaurant-explore-lite-laptop-1.png"
              imgAlt="Eate app on a laptop"
              isHorizontal
              content={[
                <ul>
                  <li>
                    <span className="text-highlight">
                      Accept and manage orders
                    </span>{" "}
                    from any device, whether it's through the web or app
                  </li>
                  <li>
                    <span className="text-highlight">Promote</span> your branded
                    digital storefront directly to your guests
                  </li>
                  <li>
                    Keep{" "}
                    <span className="text-highlight">100% of your profits</span>{" "}
                    on all your online sales
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="4"
            />
            <ColoredSquarePhoneSection
              header="Waitlist and Reservations"
              imgUrl="restaurant-explore-prem-tablet-2.png"
              imgAlt="Eate app on a tablet"
              isHorizontal
              content={[
                <ul>
                  <li>
                    <span className="text-highlight">Built-in</span> waitlist,
                    reservation, and table management
                  </li>
                  <li>
                    <span className="text-highlight">Reduce manual effort</span>{" "}
                    from entry to exit
                  </li>
                  <li>
                    <span className="text-highlight">
                      No cover, subscription, or network fees
                    </span>
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="5"
              isImgRight
            />
            <ColoredSquarePhoneSection
              header="Customer Relationship Management"
              imgUrl="restaurant-explore-prem-phone-1.png"
              imgAlt="Eate app on a mobile phone"
              content={[
                <ul>
                  <li>
                    Know where to focus your energy{" "}
                    <span className="text-highlight">
                      without any manual input
                    </span>
                  </li>
                  <li>
                    <span className="text-highlight">
                      Two-way communication
                    </span>{" "}
                    with your guests
                  </li>
                  <li>
                    <span className="text-highlight">
                      Increase lifetime value
                    </span>{" "}
                    and prioritize the right guests
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="2"
            />
            <ColoredSquarePhoneSection
              header="Kitchen Display System"
              imgUrl="restaurant-explore-prem-tablet-3.png"
              imgAlt="Eate app on a tablet"
              isHorizontal
              content={[
                <ul>
                  <li>
                    View orders from{" "}
                    <span className="text-highlight">all channels</span> on one
                    screen
                  </li>
                  <li>
                    Accurately fulfill orders{" "}
                    <span className="text-highlight">from any device</span>
                  </li>
                  <li>
                    <span className="text-highlight">No hardware fees</span>
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="6"
              isImgRight
            />
            <ColoredSquarePhoneSection
              header="Team Tools"
              imgUrl="restaurant-explore-lite-phone-2.png"
              imgAlt="Eate app on a mobile phone"
              content={[
                <ul>
                  <li>
                    <span className="text-highlight">
                      Maximize profitability
                    </span>{" "}
                    and labor costs with daily insights
                  </li>
                  <li>
                    <span className="text-highlight">
                      Automated, streamlined communication
                    </span>{" "}
                    across all restaurant functions
                  </li>
                  <li>
                    <span className="text-highlight">
                      Receive actionable insights
                    </span>{" "}
                    to make better decisions every day
                  </li>
                </ul>,
              ]}
              backgroundColor="rgba(253, 112, 40, 0.161)"
              animationNumber="3"
            />
          </section>
        </ToggleContent>
      </ToggleSliderSection>
    </div>
  </div>
);

export default Explore;
