import React from "react";

import "./ColoredSquarePhoneSectionStyles.scss";

const ColoredSquarePhoneSection = ({
  header,
  content,
  imgUrl,
  imgAlt,
  backgroundColor,
  animationNumber,
  isImgRight,
  isHorizontal = false,
  invertImagePosition = false,
}) => (
  <>
    {/*<div id={`trigger${animationNumber}${isHorizontal ? "h" : ""}`}></div>*/}
    <section className="serv_app padding-t-12 colored-square-phone-section">
      <div className="container">
        <div className="row justify-content-center">
          {isImgRight ? (
            <>
              <div className="col-lg-5 my-auto order-2 order-lg-1">
                <div className="title_sections mb-0">
                  <h2>{header}</h2>
                  <p>{content}</p>
                </div>
              </div>
              <div className="col-lg-5 mx-auto mb-4 mb-lg-0 order-1 order-lg-1">
                <div
                  className={`amo_pic${
                    isHorizontal ? " horizontal_amo_pic" : ""
                  }${invertImagePosition ? " inverted-image" : " test"}`}
                  style={{ backgroundColor: `${backgroundColor}` }}
                >
                  <img
                    //id={`animate${animationNumber}${isHorizontal ? "h" : ""}`}
                    src={require("../../../../Assets/" + imgUrl).default}
                    alt={`${imgAlt}`}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-5 mb-4 mb-lg-0">
                <div
                  className={`amo_pic${
                    isHorizontal ? " horizontal_amo_pic" : ""
                  }${invertImagePosition ? " inverted-image" : " test"}`}
                  style={{ backgroundColor: `${backgroundColor}` }}
                >
                  <img
                    //id={`animate${animationNumber}${isHorizontal ? "h" : ""}`}
                    src={require("../../../../Assets/" + imgUrl).default}
                    alt={`${imgAlt}`}
                  />
                </div>
              </div>
              <div className="col-lg-5 my-auto mx-auto">
                <div className="title_sections mb-0">
                  <h2>{header}</h2>
                  <p>{content}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  </>
);

export default ColoredSquarePhoneSection;
