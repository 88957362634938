import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import Header from "./_Shared/Header/Header";
import Footer from "./_Shared/Footer/Footer";

import Modal from "./_Shared/Modal";
import ProgressIndicator from "./_Shared/Page/ProgressIndicator/ProgressIndicator";
import Purchase from "./_Shared/Purchase";
import LoadingOverlay from "./_Shared/LoadingOverlay";

import HomePage from "./HomePage";

import WhyEate from "./Diners/WhyEate/WhyEate";
import CityExpansion from "./Diners/CityExpansion";
import ExploreRestaurants from "./Diners/ExploreRestaurants";
import Trending from "./Diners/Trending";

import RequestDemo from "./Restaurants/RequestDemo/RequestDemo";
import Pricing from "./Restaurants/Pricing";
import WhyHoste from "./Restaurants/Platform/WhyHoste/WhyHoste";
import Consulting from "./Restaurants/Consulting/Consulting";
import Explore from "./Restaurants/Platform/Explore/Explore";
import HelpCenter from "./Restaurants/HelpCenter/HelpCenter";

import GuestApp from "./Diners/GuestApp/GuestApp";
import ExploreEate from "./Explore/ExploreEate/ExploreEate";
import ExploreHoste from "./Explore/ExploreHoste/ExploreHoste";
import MemberStories from "./Explore/MemberStories";
import VideoLibrary from "./Explore/VideoLibrary";

import Careers from "./About/Careers";
import CompanyCulture from "./About/CompanyCulture/CompanyCulture";
import InThePress from "./About/InThePress";
import Investors from "./About/Investors";
import OurStory from "./About/OurStory/OurStory";

import ComingSoonAndroid from "./ComingSoonAndroid/ComingSoonAndroid";

import TheDish from "./TheDish/TheDish";

import { createAdminJwt, createCoreJwt } from "../Redux/JWT/JwtActions";

const StaticSite = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      createAdminJwt();
      createCoreJwt();
      setInitialized(true);
    }
  }, [initialized, createAdminJwt, createCoreJwt]);

  if (!document.body.classList.contains("app_fitness")) {
    document.body.className = "";
    document.body.classList.add("app_fitness", "oh-x", "loaded_page");
  }

  const minimalPagePaths = ["android"];

  const pathname = window.location.pathname.substring(1);

  const isMinimalPage = minimalPagePaths.includes(pathname);

  return (
    <div id="wrapper">
      <div id="content">
        <Header isMinimalPage={isMinimalPage} />
        <main data-spy="scroll" data-target="#navbar-example2" data-offset="0">
          <Switch>
            <Route exact path="/" component={HomePage} />

            <Route exact path="/diners/guestApp" component={GuestApp} />
            <Route exact path="/diners/WhyEate" component={WhyEate} />
            <Route exact path="/restaurants/whyHoste" component={WhyHoste} />
            <Route exact path="/restaurants/explore" component={Explore} />
            <Route exact path="/restaurants/demo" component={RequestDemo} />
            <Route
              exact
              path="/restaurants/consulting"
              component={Consulting}
            />
            <Route
              exact
              path="/restaurants/helpCenter"
              component={HelpCenter}
            />
            <Route exact path="/explore/eate" component={ExploreEate} />
            <Route exact path="/explore/hoste" component={ExploreHoste} />
            <Route
              exact
              path="/about/companyCulture"
              component={CompanyCulture}
            />
            <Route exact path="/about/ourStory" component={OurStory} />

            <Route exact path="/the-dish" component={TheDish} />

            <Route exact path="/android" component={ComingSoonAndroid} />
          </Switch>
        </main>
        <Footer isMinimalPage={isMinimalPage} />
      </div>
      <Modal />
      <ProgressIndicator />
      {/*<LoadingOverlay />*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    employee: state.employee,
  };
};

const mapDispatchToProps = {
  createAdminJwt,
  createCoreJwt,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticSite);
