import React from "react";

import "./ExploreEateStyles.scss";

import RightImageHero from "../../_Shared/Page/RightImageHero/RightImageHero";
import Accordion from "../../_Shared/Page/Accordion/Accordion";
import AccordionItem from "../../_Shared/Page/Accordion/AccordionItem";
import VideoLibrary from "../../_Shared/Page/VideoLibrary/VideoLibrary";
import VideoLibraryItem from "../../_Shared/Page/VideoLibrary/VideoLibraryItem";
import ToggleSliderSection from "../../_Shared/Page/ToggleSliderSection/ToggleSliderSection";
import ToggleContent from "../../_Shared/Page/ToggleSliderSection/ToggleContent";

const ExploreEate = () => (
  <>
    <RightImageHero
      vertCenterCol
      beforeTitleText="for"
      beforeTitleHighlight="Guests"
      header={[
        {
          text: "Provide Guests with",
        },
        {
          manipulation: "\n",
        },
        {
          text: "Unique Experiences",
        },
      ]}
      content="Provide your guests with an innovative on-and-off premise application, that allows guest the ability to locate your establishment, browse the menu, order, and pay all through their preferred device. All of which instantly connects with Hoste, to allow you to take action like never before!"
      imgUrl="explore-eate-hero.png"
      imgAlt="Various segments of the Eate App"
    />
    <div id="VideoLibrary" />
    <section className="eate-video-library">
      <div className="container">
        <ToggleSliderSection
          header="Eate Video Library"
          summary="Our application consists of complete end-to-end guest functionality available on the web or app."
          leftLabel="Mobile"
          rightLabel="Web"
          disabled={true}
          disabledMessage="Coming Soon"
        >
          <ToggleContent>
            <VideoLibrary>
              <VideoLibraryItem
                imgUrl="explore-eate-faq-browse.png"
                header="Browse"
                videoUrl="https://www.youtube.com/embed/i-mkDqv4JxI"
              >
                Review menus and pick a place to eat, this video is all about
                how we enable the dining experience.
              </VideoLibraryItem>
              <VideoLibraryItem
                imgUrl="explore-eate-faq-table.png"
                header="Find a Table"
                videoUrl="https://www.youtube.com/embed/PedlJJfPUhI"
              >
                Diners can search for available tables and make a reservation at
                your restaurant or bar.
              </VideoLibraryItem>
              <VideoLibraryItem
                imgUrl="explore-eate-faq-order.png"
                header="Check-In & Order"
                videoUrl="https://www.youtube.com/embed/IPMF_VTsaBo"
              >
                When diners get to the restaurant or bar, they can check-in with
                the server and start ordering their meal, all from the ease of
                their phone.
              </VideoLibraryItem>
              <VideoLibraryItem
                imgUrl="explore-eate-faq-pay.png"
                header="Pay Bill"
                videoUrl="https://www.youtube.com/embed/7jA7qY3d79c"
              >
                Done with their meal but can't find the server to pay? No
                worries! Diners can pay entirely from their phone and head out
                when they’re ready, all on their own time.
              </VideoLibraryItem>
              <VideoLibraryItem
                imgUrl="explore-eate-faq-my-menu.png"
                header="My Menu"
                videoUrl="https://www.youtube.com/embed/VPxsBOri6Tw"
                label="Coming Soon"
              >
                Introducing "My Menu". Personalize every restaurant's menu and
                submit your order with your item's customizations already
                applied.
              </VideoLibraryItem>
            </VideoLibrary>
          </ToggleContent>
          <ToggleContent>toggle 2</ToggleContent>
        </ToggleSliderSection>
      </div>
    </section>
    <section id="FAQ" className="faq_section shadow-none eate-faq">
      <div id="FAQ" className="container">
        <div className="row">
          <div className="col-12">
            <div className="title_sections_inner">
              <h2>FAQs</h2>
            </div>
          </div>
        </div>
        <div className="block_faq">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <Accordion id="exploreEateGeneralFaqCol1">
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol1"
                  itemNumber="1"
                  header="How do I up-skill my staff to service guests using Eate?"
                  isActive
                >
                  <p>
                    In-app tutorials, tool tips, and training videos are
                    available so your staff can maximize Eate to it’s fullest.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol1"
                  itemNumber="2"
                  header="Can guests walk out without paying?"
                >
                  <p>
                    When a guest checks in with an Eate profile, the saved
                    credit card on file will be charged at the end of the night
                    if no payment is made. We recommend ensuring payment for
                    guests without an Eate profile.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol1"
                  itemNumber="3"
                  header="Can servers still place orders for guests?"
                >
                  <p>
                    Absolutely! Servers and Managers can place orders for
                    guests, right from their preferred device.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol1"
                  itemNumber="4"
                  header="How do I manage reservations made through Eate?"
                >
                  <p>
                    Through the waitlist manager, you can easily manage your
                    waitlist right through the app.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol1"
                  itemNumber="5"
                  header="What if the guest has no Eate profile?"
                >
                  <p>
                    No problem! Servers and Managers can easily service guests,
                    place orders on their behalf, and close out checks, just as
                    they normally would.
                  </p>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="col-md-6 col-lg-6">
              <Accordion id="exploreEateGeneralFaqCol2">
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol2"
                  itemNumber="1"
                  header="How do I know where guests are sitting?"
                >
                  <p>
                    You can assign guests to tables during check in, or through
                    the waitlist management section of Hoste.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol2"
                  itemNumber="2"
                  header="Can I control whether guests can place orders?"
                >
                  <p>
                    Yes, Managers can turn off guest ordering, at any time. Both
                    Managers and Servers can continue to place orders through
                    their preferred devices.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol2"
                  itemNumber="3"
                  header="Can I preview the menus guests see?"
                >
                  <p>
                    Yes, Managers and Servers can preview all menus before
                    publishing to guests.
                  </p>
                </AccordionItem>
                <AccordionItem
                  accordionId="exploreEateGeneralFaqCol2"
                  itemNumber="4"
                  header="How quickly will guests see updates to the menu?"
                >
                  <p>
                    Immediately! Updating menus, menu items, and menu pricing
                    will appear on guest devices as soon as you apply your
                    changes.
                  </p>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default ExploreEate;
