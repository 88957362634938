import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createAdminJwt, createCoreJwt } from "../Redux/JWT/JwtActions";
import { verifyNumber, verificationCheck } from "../Redux/Twilio/TwilioActions";
import { fetchFeatureFlags } from "../Redux/LaunchDarkly/LaunchDarklyActions";
import { fetchEmployee } from "../Redux/Employee/EmployeeActions";
import { validateEmployee } from "../Redux/ValidateEmployee/ValidateEmployeeActions";

import "./SignInStyles.scss";

const SignIn = ({
  history,
  createAdminJwt,
  createCoreJwt,
  jwt,
  employee,
  twilio,
  launchDarkly,
  fetchFeatureFlags,
  verifyNumber,
  verificationCheck,
  fetchEmployee,
  validateEmployee,
  validEmployee,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [showPassCode, setShowPassCode] = useState(false);
  const [validatedAccount, setValidatedAccount] = useState(false);

  const [phone, setPhone] = useState("");
  const [verifyCode, setVerifyCode] = useState("");

  document.body.className = "";
  document.body.classList.add("signup_full");

  const OnVerifyPhoneNumber = (event) => {
    event.preventDefault();

    if (
      launchDarkly.featureFlags.useTwilio &&
      process.env.REACT_APP_ENVIRONMENT !== "local"
    ) {
      verifyNumber(phone);
    }

    setShowPassCode(true);
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  useEffect(() => {
    if (initialized && phone.length === 14) {
      validateEmployee(phone.replace(/\D/g, ""));
    } else {
      setValidatedAccount(false);
      setShowPassCode(false);
    }
  }, [initialized, phone, validateEmployee]);

  useEffect(() => {
    if (initialized && !validEmployee.isLoading && validEmployee.data) {
      setValidatedAccount(true);
    } else {
      setValidatedAccount(false);
    }
  }, [initialized, validEmployee]);

  useEffect(() => {
    if (!initialized) {
      if (!jwt.admin.bearer) {
        createAdminJwt();
      }
      if (!jwt.core.bearer) {
        createCoreJwt();
      }
      setInitialized(true);
    }
  }, [initialized, jwt, createAdminJwt, createCoreJwt]);

  useEffect(() => {
    if (initialized) {
    }
  }, [initialized, validEmployee]);

  useEffect(() => {
    if (initialized) {
      if (!!jwt.core.bearer) {
        fetchFeatureFlags();
      }
    }
  }, [initialized, fetchFeatureFlags, jwt.core]);

  useEffect(() => {
    if (initialized) {
      if (
        !launchDarkly.featureFlags.useTwilio ||
        process.env.REACT_APP_ENVIRONMENT === "local"
      ) {
        if (verifyCode === process.env.REACT_APP_TWILIO_PASSCODE) {
          fetchEmployee(phone.replace(/\D/g, ""));
        }
      } else if (
        !!verifyCode &&
        verifyCode.length === 4 &&
        !!twilio.verify &&
        twilio.verify?.data === "pending" &&
        launchDarkly.featureFlags.useTwilio
      ) {
        verificationCheck(phone, verifyCode);
      }
    }
  }, [
    initialized,
    verifyCode,
    twilio.verify,
    verificationCheck,
    launchDarkly,
    phone,
    fetchEmployee,
  ]);

  useEffect(() => {
    if (initialized) {
      if (!!twilio.check && twilio.check?.data === "approved") {
        fetchEmployee(phone.replace(/\D/g, ""));
      }
    }
  }, [initialized, fetchEmployee, twilio.check, phone]);

  useEffect(() => {
    if (initialized && !!employee && !!employee?.data) {
      //if data was successful
      setPhone("");
      setVerifyCode("");
      setShowPassCode(false);
      setValidatedAccount(false);

      history.push("/Dashboard");
    }
  }, [initialized, employee, setPhone, setVerifyCode, history]);

  return (
    <>
      <div className="item_brand">
        <div className="container">
          <Link to="/">
            <img src="../../assets/img/logo.svg" alt="" />
          </Link>
        </div>
      </div>
      <section className="form_signup_one">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-5 ml-auto">
              <div className="item_group">
                <form onSubmit={OnVerifyPhoneNumber} className="row">
                  <div className="col-12">
                    <div className="title_sign">
                      <h2>Sign in</h2>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="(###) ###-####"
                        value={phone}
                        onChange={(e) =>
                          setPhone(formatPhoneNumber(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {validatedAccount && (
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn w-100  btn_account bg-orange2 c-white rounded-8"
                      >
                        Verify Phone Number
                      </button>
                    </div>
                  )}

                  {showPassCode && validatedAccount && (
                    <div className="col-md-12">
                      <div
                        className="form-group --password"
                        id="show_hide_password"
                      >
                        <label>Enter Pass Code</label>
                        <div className="input-group">
                          <input
                            type="password"
                            maxLength={4}
                            className="form-control"
                            data-toggle="password"
                            placeholder="####"
                            required=""
                            value={verifyCode}
                            onChange={(e) => setVerifyCode(e.target.value)}
                          />
                          <div className="input-group-prepend hide_show">
                            <a href="">
                              <span className="input-group-text tio hidden_outlined"></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="item_footer">
        <div className="container">
          <p>
            © 2020 <Link to="/">Eate Digital Solutions, Inc.</Link> All Right
            Reseved
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    twilio: state.twilio,
    launchDarkly: state.launchDarkly,
    employee: state.employee,
    jwt: state.jwt,
    validEmployee: state.validEmployee,
  };
};

const mapDispatchToProps = {
  createAdminJwt,
  createCoreJwt,
  verifyNumber,
  verificationCheck,
  fetchFeatureFlags,
  fetchEmployee,
  validateEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
