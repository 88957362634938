import React from "react";

import "./ContentSliderStyles.scss";

const ArticleSlideContent = ({ title, subtitle, links }) => {
  return (
    <div className="article-slider-content">
      <p className="article-title">{title}</p>
      <p className="article-subtitle">{subtitle}</p>
      <div className="article-link-content">
        <p>Click icons to read more...</p>
        <div className="article-links">
          {links.map((l) => (
            <a href={l.link} target="_blank" rel="noreferrer">
              <img
                src={require(`../../../../Assets/Icons/${l.icon}`).default}
                className="article-icon"
                alt={"social media icon"}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticleSlideContent;
