import * as types from "./EmployeeActionTypes";
import initialState from "../InitialState";

const accountReducer = (state = initialState.employeeDetails, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_STARTED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case types.FETCH_EMPLOYEE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case types.FETCH_EMPLOYEE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default accountReducer;
