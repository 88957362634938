import React from "react";

import TextStyling from "../TextStyling/TextStyling";

import "./RightImageHeroStyles.scss";

const RightImageHero = ({
  additionalClass,
  largeHeader,
  longText,
  vertCenterCol,
  beforeTitleText,
  beforeTitleHighlight,
  header,
  content,
  imgUrl,
  imgAlt,
  children,
}) => (
  <section
    className={`${
      additionalClass ? additionalClass + " " : ""
    } features_section pt_banner_inner right-image-hero`}
  >
    <div className="container">
      <div
        className={`${
          vertCenterCol ? "align-items-center " : "align-items-end "
        }row`}
      >
        <div className={`${longText ? "col-lg-7 " : "col-lg-6 "}col-md-8`}>
          <div className="title_sections mb-5 mb-lg-0">
            {beforeTitleText && beforeTitleHighlight ? (
              <div className="before_title">
                {beforeTitleText ? <span>{beforeTitleText}</span> : ""}
                {beforeTitleHighlight ? (
                  <span>{beforeTitleHighlight}</span>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <h1
              className={`${
                largeHeader ? "right-image-hero-header-large" : ""
              }`}
            >
              <TextStyling text={header} />
            </h1>
            {children ? children : <p>{content}</p>}
          </div>
        </div>
        <div className={`${longText ? "col-lg-5" : "col-lg-6"}`}>
          <div className="images_desc">
            <img
              src={require("../../../../Assets/" + imgUrl).default}
              alt={`${imgAlt}`}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default RightImageHero;
