import * as types from "./NewsletterActionTypes";
import * as newsletterService from "./NewsletterServices";

const subscribeStarted = () => ({
  type: types.POST_SUBSCRIBE_STARTED,
});

const subscribeSucceeded = (data) => ({
  type: types.POST_SUBSCRIBE_SUCCEEDED,
  payload: data,
});

const subscribeFailed = (error) => ({
  type: types.POST_SUBSCRIBE_FAILED,
  payload: error,
});

export const subscribe = (data) => (dispatch) => {
  dispatch(subscribeStarted());
  return newsletterService.subscribe(JSON.stringify(data)).then(
    (response) => {
      dispatch(subscribeSucceeded(response.data));
    },
    (error) => {
      dispatch(subscribeFailed(error));
    }
  );
};

const unsubscribeStarted = () => ({
  type: types.POST_UNSUBSCRIBE_STARTED,
});

const unsubscribeSucceeded = (data) => ({
  type: types.POST_UNSUBSCRIBE_SUCCEEDED,
  payload: data,
});

const unsubscribeFailed = (error) => ({
  type: types.POST_UNSUBSCRIBE_FAILED,
  payload: error,
});

export const unsubscribe = (email, type) => (dispatch) => {
  dispatch(unsubscribeStarted());
  return newsletterService.unsubscribe(email, type).then(
    (response) => {
      dispatch(unsubscribeSucceeded(response.data));
    },
    (error) => {
      dispatch(unsubscribeFailed(error));
    }
  );
};
