import React from "react";

import "./ContentSliderStyles.scss";

const QuoteSlideContent = ({ quote, author, authorTitle }) => {
  return (
    <>
      <div className="quote-slider-content">
        <p className="quote">{quote}</p>
        <p className="quote-author">{author}</p>
        <p className="author-title">{authorTitle}</p>
      </div>
      <img
        src={require("../../../../Assets/quote.png").default}
        className="quote-img"
        alt={"quotation mark"}
      />
    </>
  );
};

export default QuoteSlideContent;
