import React from "react";
import ContentSlide from "./ContentSlide";

import "./ContentSliderStyles.scss";
import redBackground from "../../../../Assets/content-slider-background-red.png";
import blueBackground from "../../../../Assets/content-slider-background-blue.png";
import tanBackground from "../../../../Assets/content-slider-background-tan.png";

const ContentSlider = ({
  header,
  backgroundImgColor = "red",
  backgroundSize = "90% 425px",
  sliderClass,
  slides,
}) => {
  const background =
    backgroundImgColor === "tan"
      ? tanBackground
      : backgroundImgColor === "blue"
      ? blueBackground
      : redBackground;
  console.log(background);
  const columns = Math.round(slides.length / 2);

  const renderedSlides = [];

  for (let i = 0; i < columns; i++) {
    const topSlide = slides[i * 2];
    const bottomSlide = slides[i * 2 + 1];

    const individualSlides = [topSlide];

    if (!!bottomSlide) {
      individualSlides.push(bottomSlide);
    }

    renderedSlides.push(
      <ContentSlide
        key={`contentSlide_${i + 1}`}
        columnNumber={i + 1}
        individualSlides={individualSlides}
      />
    );
  }

  return (
    <section
      className="stories__customers customers_app social-media-slider content-slider"
      style={{
        backgroundImage: `url("${background}")`,
        backgroundSize: backgroundSize,
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9 offset-lg-3">
            <div className="title_sections" id="Stories">
              <h2>{header}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row social-media-slider-row">
          <div className="col-lg-9 offset-lg-3">
            <div className="body__swipe">
              <div
                className={`swiper-container swiper__center ${sliderClass} swiper-container-initialized swiper-container-horizontal`}
              >
                <div className="swiper-wrapper">{renderedSlides}</div>
              </div>

              <div
                className={`swiper-button-next ${sliderClass}-button-next`}
                tabIndex="0"
                role="button"
                aria-label="Next slide"
                aria-disabled="false"
              >
                <i className="tio chevron_right"></i>
              </div>

              <div
                className={`swiper-button-prev ${sliderClass}-button-prev swiper-button-disabled${
                  backgroundImgColor === "tan" ? " light-arrow" : ""
                }`}
                tabIndex="0"
                role="button"
                aria-label="Previous slide"
                aria-disabled="true"
              >
                <i className="tio chevron_left"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentSlider;
