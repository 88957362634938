import * as types from "./EmployeeActionTypes";
import * as employeeService from "./EmployeeServices";
//import { formatPhoneNumber } from "../../Helpers/Extensions";

const fetchEmployeeStarted = () => ({
  type: types.FETCH_EMPLOYEE_STARTED,
});

const fetchEmployeeSucceeded = (data) => ({
  type: types.FETCH_EMPLOYEE_SUCCEEDED,
  payload: data,
});

const fetchEmployeeFailed = (error) => ({
  type: types.FETCH_EMPLOYEE_FAILED,
  payload: error,
});

export const fetchEmployee = (phone) => (dispatch) => {
  dispatch(fetchEmployeeStarted());
  return employeeService.find(phone).then(
    (response) => {
      const data = response.data.data;
      dispatch(fetchEmployeeSucceeded(data));
    },
    (error) => {
      dispatch(fetchEmployeeFailed(error));
    }
  );
};
