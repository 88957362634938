import * as types from "./TwilioActionTypes";
import initialState from "../InitialState";

const twilioReducer = (state = initialState.twilioDetails, action) => {
  switch (action.type) {
    case types.VERIFY_NUMBER_STARTED:
      return {
        ...state,
        isLoading: true,
        verify: action.payload,
        error: null,
      };
    case types.VERIFY_NUMBER_FAILED:
      return {
        ...state,
        isLoading: false,
        verify: null,
        error: action.payload,
      };
    case types.VERIFY_NUMBER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        verify: action.payload,
        error: null,
      };
    case types.VERIFICATION_CHECK_STARTED:
      return {
        ...state,
        isLoading: false,
        check: action.payload,
        error: null,
      };
    case types.VERIFICATION_CHECK_FAILED:
      return {
        ...state,
        isLoading: false,
        check: null,
        error: action.payload,
      };
    case types.VERIFICATION_CHECK_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        check: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default twilioReducer;
