import React from "react";

import "./HeroSectionStyles.scss";

const HeroSection = ({
  isAnimated = true,
  content,
  isFirst,
  imageUrl,
  children,
}) => {
  return (
    <section
      className={`${
        isFirst ? "pt_banner_inner " : ""
      }no-before banner_app_two banner_app_three hero-section`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="banner_title">
              <h1 className="margin-b-2">{children[0]}</h1>
              <p className="c-dark">{content}</p>
            </div>
            <div className="button_app">{children[1]}</div>
          </div>
        </div>
        <div className="hero-img">
          <img
            src={require("../../../../Assets/" + imageUrl).default}
            className={
              isAnimated ? "img_bottom floating-simple2" : "img_bottom"
            }
            alt="Eate app on a mobile phone"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
