import React from "react";

const IconBoxSection = ({ columnSize, imgUrl, header, bgColor, children }) => (
  <div className={`col-lg-${columnSize} icon-boxes`}>
    <div className="items_serv sevice_block">
      <div className="icon--top" style={{ backgroundColor: `${bgColor}` }}>
        <img
          src={require("../../../../Assets/Icons/" + imgUrl).default}
          alt=""
        />
      </div>
      <div className="txt">
        <h3>{header}</h3>
        <p>{children}</p>
      </div>
    </div>
  </div>
);

export default IconBoxSection;
