import React from "react";

import RightImageHero from "../../_Shared/Page/RightImageHero/RightImageHero";
import ToggleSliderSection from "../../_Shared/Page/ToggleSliderSection/ToggleSliderSection";
import ToggleContent from "../../_Shared/Page/ToggleSliderSection/ToggleContent";
import Founder from "./Founder";
import TeamMember from "./TeamMember";

import "./OurStoryStyles.scss";

const WhoWeAre = () => (
  <div className="our-story">
    <RightImageHero
      largeHeader
      longText
      header={[
        {
          text: "The ",
        },
        {
          text: "Eate",
          manipulation: "highlight",
        },
        {
          text: " Story",
        },
      ]}
      imgUrl="our-story-hero.png"
      imgAlt="Woman holding plate of food"
    >
      <p>
        Operators should benefit from a smarter, more centralized way to manage
        their businesses and the guests that interact with those businesses. For
        too long, restaurant operators have dealt with multiple legacy platforms
        that don’t simplify their operations, but negatively add operational
        burden and fixed costs to already thin margins -- and with so many
        systems, restauranteurs are not obtaining any additional intelligence on
        their operational performance or their guests habits, behaviors and
        preferences.
      </p>
      <p>
        We sought out to change that, by creating a simplified platform, that
        streamlines operations, centralizes revenue streams, and enables unique
        experiences for guests all while obtaining the right data needed to
        drive operational, financial, and marketing decision making.
      </p>
      <p>
        Interested in Joining the Eate Team :: Please email us at{" "}
        <a href="mailto:People@eatedigital.com">People@eatedigital.com</a>
      </p>
    </RightImageHero>

    <ToggleSliderSection
      header="Meet the Eate Team"
      leftLabel="Founders"
      rightLabel="Strategic team"
    >
      <ToggleContent>
        <section className="gb_team_te team-founders">
          <div className="container">
            <div className="row">
              <div className="w-100 order-sm-4"></div>
              <div className="col-lg-3 order-4 order-lg-4 mb-3 mb-lg-0 col-md-6">
                <Founder
                  imgUrl="our-story-founder-cto.png"
                  imgAlt="CTO Headshot"
                  name="Lance"
                  profileUrl="robinsonlance"
                  position="Chief Technology Officer"
                />
              </div>
              <div className="col-lg-3 mx-auto order-2 order-lg-2 mb-3 mb-lg-0 col-md-6">
                <Founder
                  imgUrl="our-story-founder-ceo.png"
                  imgAlt="CEO Headshot"
                  name="Jefferey"
                  profileUrl="jdagbo"
                  position="Chief Executive Officer"
                />
              </div>
              <div className="col-lg-3 order-3 order-lg-3 mb-3 mb-lg-0 col-md-6">
                <Founder
                  imgUrl="our-story-founder-coo.png"
                  imgAlt="COO Headshot"
                  name="Max"
                  profileUrl="maxkogan21"
                  position="Chief Operating Officer"
                />
              </div>
              <div className="col-lg-3 mt-5 order-1 order-lg-1 mb-3 mb-lg-0">
                <div className="title_sections">
                  <h2>
                    Our
                    <br />
                    <span className="c-orange2">Founders</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ToggleContent>
      <ToggleContent>
        <section className="team_section team-members" id="Team">
          <div className="switch_team">
            <div className="particl_colors">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="tab-content content_team" id="pills-tabContent">
              <div
                className="tab-pane itemperson fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row ">
                  <TeamMember
                    imgUrl="our-story-team-member-mike.png"
                    imgAlt="Chief Restaurant Officer Headshot"
                    profileUrl="michael-dickey-507b65210"
                    name="Mike"
                    position="Chief Restaurant Officer"
                  />
                  <TeamMember
                    imgUrl="our-story-team-member-james.png"
                    imgAlt="Advisor, Executive Chef Headshot"
                    url="tribe-chicago.com"
                    name="James"
                    position="Advisor, Executive Chef"
                  />
                  <TeamMember
                    imgUrl="our-story-team-member-nicole.png"
                    imgAlt="Software Engineer Headshot"
                    profileUrl="nicolegrinsell"
                    name="Nicole"
                    position="Software Engineer"
                  />
                  <TeamMember
                    imgUrl="our-story-team-member-sherelyn.png"
                    imgAlt="Software Engineer Headshot"
                    profileUrl="sherelyn-saceda"
                    name="Sherelyn"
                    position="Software Engineer"
                  />
                  <TeamMember
                    imgUrl="our-story-team-member-shannon.png"
                    imgAlt="Marketing & Creative Services Headshot"
                    profileUrl="shannonmullenokeefe"
                    name="Shannon"
                    position="Marketing & Creative Services"
                  />
                  <TeamMember
                    imgUrl="our-story-team-member-caren.png"
                    imgAlt="Advisor, Product Headshot"
                    profileUrl="carenpark"
                    name="Caren"
                    position="Advisor, Product"
                    colSize={3}
                  />
                  <TeamMember
                    imgUrl="our-story-team-member-emil.png"
                    imgAlt="VP of Product Design Headshot"
                    profileUrl="kantarev"
                    name="Emil"
                    position="VP of Product Design"
                  />
                  {/*<TeamMember
                      imgUrl="our-story-team-member-abaya.png"
                      imgAlt="VP, Strategic Partnerships"
                      profileUrl="abayadake"
                      name="Abaya"
                      position="VP, Strategic Partnerships"
                    />*/}
                  <TeamMember
                    imgUrl="our-story-blank-profile.jpg"
                    imgAlt="CFO Headshot"
                    name="Brandon"
                    position="Chief Financial Officer"
                  />
                  <TeamMember
                    imgUrl="our-story-blank-profile.jpg"
                    imgAlt="CPO Headshot"
                    name="Ronald"
                    position="Chief People Officer"
                  />
                  <TeamMember
                    imgUrl="our-story-blank-profile.jpg"
                    imgAlt="VP of Product Headshot"
                    name="Tod"
                    position="VP of Product"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </ToggleContent>
    </ToggleSliderSection>
  </div>
);

export default WhoWeAre;
