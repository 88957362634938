import React from "react";

import ColoredSquarePhoneSection from "../../../_Shared/Page/ColoredSquarePhoneSection/ColoredSquarePhoneSection";

import "../PlatformStyles.scss";
import "./WhyHosteStyles.scss";

const WhyEate = () => (
  <div class="restaurant-platform why-hoste">
    <section
      className="demo_1 demo__charity demo__software restaurant-platform-hero"
      id="About"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-5">
            <div className="banner_title">
              <h1>Instantly enable more profitable interactions.</h1>
              <p>
                Get smarter data to obtain the right level of intelligence on
                your staff and guests to make smarter financial, operational,
                and marketing decisions.
              </p>
              <a
                href="#learnMore"
                className="btn btn_lg_primary bg-blue c-white smoothScroll"
              >
                <div className="inside_item">
                  <span>Learn More</span>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="element_ui">
              <img
                src={require("../../../../Assets/why-hoste-hero.png").default}
                alt="Queue card in Eate app"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="learnMore">
      <h2 className="benefits-title">Platform Benefits</h2>
      <ColoredSquarePhoneSection
        header="Simplified Operations"
        imgUrl="why-hoste-tablet-1.png"
        imgAlt="Eate app on a mobile tablet"
        invertImagePosition
        content={[
          <ul>
            <li>Reduce fixed expenses across legacy platforms</li>
            <li>Obtain intellectual data to make better business decisions</li>
            <li>Make better staffing and scheduling decisions</li>
          </ul>,
        ]}
        backgroundColor="rgba(253, 112, 40, 0.161)"
        isHorizontal
        animationNumber="2"
      />
      <ColoredSquarePhoneSection
        header="Drive Guest Frequency"
        imgUrl="why-hoste-phone-2.1.png"
        imgAlt="Eate app on a mobile phone"
        content={[
          <ul>
            <li>
              Instantly get real-time interactions between staff and guests
            </li>
            <li>Simplified order and pay process to help increase revenue</li>
            <li>Direct marketing to all unique guests on the platform</li>
          </ul>,
        ]}
        backgroundColor="rgba(253, 112, 40, 0.161)"
        animationNumber="3"
        isImgRight
      />
      <ColoredSquarePhoneSection
        header="Create Unique Guest Experiences "
        imgUrl="why-hoste-phone-3.png"
        imgAlt="Eate app on a mobile phone"
        content={[
          <ul>
            <li>Showcase your restaurant directly to all types of guests</li>
            <li>React directly to guest feedback in real time</li>
            <li>
              Reduce errors, waste and food costs through guest enabled ordering
            </li>
          </ul>,
        ]}
        backgroundColor="rgba(253, 112, 40, 0.161)"
        animationNumber="4"
      />
    </div>
  </div>
);

export default WhyEate;
