import axios from "axios";
import { getStoreState } from "./index";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_EATE_ADMIN_API_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
});

const apiFactory = () => {
  return axiosInstance;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const currentState = getStoreState();
    const jwt = currentState?.jwt;

    let token = "";

    if (config.url !== "/Connect/Token") {
      token = `Bearer ${jwt.admin.bearer}`;
    } else if (config.url === "/Connect/Token") {
      token = `Basic ${jwt.admin.basic}`;
    }

    config.headers["Authorization"] = `${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiFactory;
