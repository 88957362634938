import apiFactory from "../CoreApiFactory";

const apiGateway = apiFactory();

export const getFeatureFlags = (role) => {
  if (!!role) {
    return apiGateway.get(`/LaunchDarkly/GetFeatureFlags/${role}`);
  }
  return apiGateway.get(`/LaunchDarkly/GetFeatureFlags`);
};
