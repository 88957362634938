import React, { useState } from "react";

import "./ToggleSliderSectionStyles.scss";

const ToggleSliderSection = ({
  header,
  summary,
  leftHeader,
  leftSummary,
  leftLabel,
  rightHeader,
  rightSummary,
  rightLabel,
  useConditionalText = false,
  disabled = false,
  disabledMessage,
  children,
}) => {
  const [toggleSliderActive, setToggleSliderActive] = useState(false);

  function handleToggleClick() {
    setToggleSliderActive(!toggleSliderActive);
  }

  return (
    <section className="toggle-slider-section">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-lg-8 text-center">
            <div className="title_sections_inner margin-b-5">
              {!!header || useConditionalText ? (
                <h2>
                  {useConditionalText
                    ? toggleSliderActive
                      ? rightHeader
                      : leftHeader
                    : header}
                </h2>
              ) : null}
              {!!summary || useConditionalText ? (
                <p>
                  {useConditionalText
                    ? toggleSliderActive
                      ? rightSummary
                      : leftSummary
                    : summary}
                </p>
              ) : null}
              <div className="toggle_switch margin-t-4">
                <label className="toggler" id="filt-left">
                  {leftLabel}
                </label>

                <div className="toggle" onClick={handleToggleClick}>
                  <input
                    type="checkbox"
                    id="switcher"
                    className="check"
                    disabled={disabled ? "disabled" : ""}
                  />
                  <b
                    className={
                      disabled ? "b switch disabled-switch" : "b switch"
                    }
                  ></b>
                </div>

                <div className="d-inline-block">
                  <label className="toggler" id="filt-right">
                    {rightLabel}
                  </label>
                  {disabledMessage ? (
                    <span class="switch-label">Coming Soon</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggleSliderActive ? children[1] : children[0]}
    </section>
  );
};

export default ToggleSliderSection;
