import { combineReducers } from "redux";
import employee from "./Employee/EmployeeReducer";
import newsletter from "./Newsletter/NewsletterReducer";
import jwt from "./JWT/JwtReducer";
import launchDarkly from "./LaunchDarkly/LaunchDarklyReducer";
import svgIcons from "./SvgIcons/SvgIconsReducer";
import twilio from "./Twilio/TwilioReducer";
import validEmployee from "./ValidateEmployee/ValidateEmployeeReducer";

const rootReducer = combineReducers({
  employee,
  jwt,
  newsletter,
  svgIcons,
  launchDarkly,
  twilio,
  validEmployee,
});

export default rootReducer;
