import React from "react";

import "./ContentSliderStyles.scss";

const MapSlideContent = ({ title, category, location }) => {
  return (
    <div className="map-slider-content">
      <p className="description">{title}</p>
      <div className="map-location-content">
        <div className="category-location-content">
          <p className="location-category">{category}</p>
          <div className="location-pin">
            <img
              src={require("../../../../Assets/map-pin.png").default}
              className="pin-img"
              alt={"small street map"}
            />
            <p className="location">{location}</p>
          </div>
        </div>
        <div className="map-content">
          <img
            src={require("../../../../Assets/map.png").default}
            className="map-img"
            alt={"small street map"}
          />
        </div>
      </div>
    </div>
  );
};

export default MapSlideContent;
