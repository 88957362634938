import React from "react";

const VideoLibraryItem = ({ videoUrl, imgUrl, header, children, label }) => (
  <div className="col-lg-4 col-md-6">
    <a
      className="library-item-wrapper btn_video"
      data-toggle="modal"
      data-src={`${videoUrl}`}
      data-target="#mdllVideo"
    >
      <img src={require("../../../../Assets/" + imgUrl).default} />
      <div className="library-item-content">
        <h3>
          {header}
          {label ? <span className="video-library-label">{label}</span> : ""}
        </h3>

        <p>{children}</p>
      </div>
    </a>
  </div>
);

export default VideoLibraryItem;
