import * as types from "./SvgIconsActionTypes";
import initialState from "../InitialState";

const svgIconReducer = (state = initialState.svgIconsDetails, action) => {
  switch (action.type) {
    case types.FETCH_SVG_ICONS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.FETCH_SVG_ICONS_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        error: action.payload,
      };
    case types.FETCH_SVG_ICONS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case types.UPDATE_SVG_ICON_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.UPDATE_SVG_ICON_FAILED:
      return {
        ...state,
        isLoading: false,
        icon: null,
        error: action.payload,
      };
    case types.UPDATE_SVG_ICON_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        icon: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default svgIconReducer;
