import * as types from "./JwtActionTypes";
import * as adminApiJwtService from "./AdminApiJwtServices";
import * as coreApiJwtService from "./CoreApiJwtServices";

const createAdminJwtStarted = () => ({
  type: types.CREATE_ADMIN_JWT_STARTED,
});

const createAdminJwtSucceeded = (data) => ({
  type: types.CREATE_ADMIN_JWT_SUCCEEDED,
  payload: data,
});

const createAdminJwtFailed = (error) => ({
  type: types.CREATE_ADMIN_JWT_FAILED,
  payload: error,
});

export const createAdminJwt = () => async (dispatch) => {
  dispatch(createAdminJwtStarted());

  return adminApiJwtService.getToken().then(
    (response) => {
      dispatch(createAdminJwtSucceeded(response));
    },
    (error) => {
      dispatch(createAdminJwtFailed(error));
    }
  );
};

const createCoreJwtStarted = () => ({
  type: types.CREATE_CORE_JWT_STARTED,
});

const createCoreJwtSucceeded = (data) => ({
  type: types.CREATE_CORE_JWT_SUCCEEDED,
  payload: data,
});

const createCoreJwtFailed = (error) => ({
  type: types.CREATE_CORE_JWT_FAILED,
  payload: error,
});

export const createCoreJwt = () => async (dispatch) => {
  dispatch(createCoreJwtStarted());

  return coreApiJwtService.getToken().then(
    (response) => {
      dispatch(createCoreJwtSucceeded(response));
    },
    (error) => {
      dispatch(createCoreJwtFailed(error));
    }
  );
};
