import React from "react";

import TextStyling from "../../_Shared/Page/TextStyling/TextStyling";
import ColoredSquareInfoSection from "../../_Shared/Page/ColoredSquarePhoneSection/ColoredSquarePhoneSection";

import "./WhyEateStyles.scss";
import ColoredSquarePhoneSection from "../../_Shared/Page/ColoredSquarePhoneSection/ColoredSquarePhoneSection";

const WhyEate = () => (
  <div class="why-eate">
    <section className="pt_banner_inner banner_about_three why-eate-header">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-5">
            <div className="banner_title_inner margin-b-3">
              <h1
                data-aos="fade-up"
                data-aos-delay="0"
                className="aos-init aos-animate"
              >
                <TextStyling
                  text={[
                    {
                      text: "Why ",
                    },
                    {
                      text: "Eate",
                      manipulation: "highlight",
                    },
                  ]}
                />
              </h1>
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                className="aos-init aos-animate"
              >
                Because Eate lets you take full control of your restaurant
                experience, easily and effortlessly — all from your phone.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container pl-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="multi_image">
              <img
                src={require("../../../Assets/why-eate-header-1.png").default}
                alt="Server waiting to take order"
              />
              <img
                src={require("../../../Assets/why-eate-header-2.png").default}
                alt="Diner on the Eate app"
              />
              <img
                src={require("../../../Assets/why-eate-header-3.png").default}
                alt="Group having drinks together"
              />
              <div className="group_img">
                <img
                  src={require("../../../Assets/why-eate-header-4.png").default}
                  alt="Diner on the Eate app"
                />
                <img
                  src={require("../../../Assets/why-eate-header-5.png").default}
                  alt="Various meals on a table"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ColoredSquarePhoneSection
      header="Control the Dining Experience, Digitally"
      imgUrl="why-eate-phone-1.png"
      imgAlt="Eate app on a mobile phone"
      content="Even before you sit down at the table, start placing menu items in your cart, invite your friends to join the waitlist, and detail any food allergies, all from the ease of your phone. Once at the restaurant, it’s easy to send that order directly to the kitchen, and even easier to split the bill, pay, and walk out, all without the support of any busy waitstaff!"
      backgroundColor="#FBEDD3"
    />
    <ColoredSquarePhoneSection
      header="Personal Devices means Less Physical Touchpoints and Increased Safety"
      imgUrl="why-eate-phone-2.1.png"
      imgAlt="Eate app on a mobile phone"
      content="By utilizing your own smart phone, you’re able to reduce the amount of people you’re exposed to, and the amount of times that you’re exposed to them, which ensures you’re as healthy as you can be!"
      backgroundColor="#FBEDD3"
      isImgRight
    />
  </div>
);

export default WhyEate;
