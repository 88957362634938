import React from "react";

import RightImageHero from "../../_Shared/Page/RightImageHero/RightImageHero";
import IconBoxSection from "../../_Shared/Page/IconBoxSection/IconBoxSection";
import SquareIconBox from "../../_Shared/Page/IconBoxSection/SquareIconBox";

import "./CompanyCultureStyles.scss";

const CompanyCulture = () => (
  <>
    <RightImageHero
      additionalclassName="grayGradient"
      largeHeader
      header={[
        {
          text: "Eate",
          manipulation: "highlight",
        },
        {
          text: " Culture",
        },
      ]}
      content="At Eate Digital our culture and values drive everything that we do. As an organization we have taken the time to understand what makes us unique and have been intentional about how we hire, make decisions, and service our restaurateurs and diners. As we continue to grow, we believe that our culture and values will be a competitive advantage that allows us to recruit and retain the best talent that can consistently produce innovative solutions, ultimately leading to exponential growth."
      imgUrl="culture-hero.png"
      imgAlt="Eate logo"
    />
    <div className="container company-culture-values">
      <div className="row">
        <div className="col-12 text-center">
          <h2>Our Core Values</h2>
          <p>
            These four areas and our ability to create habits and behaviors
            around them makes Eate unique. While some of these may sound like
            things you’ve heard before, our people, our ability to execute and
            have fun while doing it, is a true differentiator.
          </p>
        </div>
      </div>
    </div>
    <IconBoxSection>
      <SquareIconBox
        columnSize="6"
        header="Continuous Improvement is At Our Core"
        bgColor="#3C7FF1"
        imgUrl="line-graph-icon.svg"
      >
        As an organization we consistently challenge the status quo and look for
        new ways to be more efficient, effective, and profitable. We despise the
        phrase “We’ve always done it this way”, as it goes against our desire
        and ability to innovate and can potentially silence the voice of
        newcomers.
      </SquareIconBox>
      <SquareIconBox
        columnSize="6"
        header="A Rewards System That Benefits the WHOLE Person"
        bgColor="#F7CC62"
        imgUrl="gift-icon.svg"
      >
        The success of our business is directly tied to the satisfaction levels
        of our employees. Whether it is a competitive salary and benefits
        package, a virtual game night with other team members, or a personalized
        birthday present based on your passion, our goal is to make the employee
        experience unforgettable (in a good way 😁).
      </SquareIconBox>
      <SquareIconBox
        columnSize="6"
        header="Autonomy Breeds Work/Life Balance"
        bgColor="#F88161"
        imgUrl="smile-icon-light.svg"
      >
        We are a remote-first company and care more about the quality of the
        deliverables, than the location that you are working in. We encourage
        our people to enjoy the fruits of their labor and step away from the
        workplace to recharge.
      </SquareIconBox>
      <SquareIconBox
        columnSize="6"
        header="Diversity Drives Our Decision Making "
        bgColor="#1FB692"
        imgUrl="flower-icon.svg"
      >
        We are a diverse firm, and we seek to be diverse in how we look, think,
        and act. We consistently review our approach to building and leading
        diverse teams, as we know it will lead to a better work environment and
        business results. 
      </SquareIconBox>
    </IconBoxSection>
  </>
);

export default CompanyCulture;
