import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./Redux/index";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AdminPortalRoute from "./AdminPortal/AdminPortalRoute";

const store = configureStore();

const urlParams = new URLSearchParams(window.location.search);
const routeToAdmin =
  window.location.host.includes("admin") || urlParams.get("admin") === "true";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {routeToAdmin && <AdminPortalRoute />}
        {!routeToAdmin && <App />}
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
