import * as types from "./ValidateEmployeeActionTypes";
import * as validateEmployeeService from "./ValidateEmployeeServices";

const validateEmployeeStarted = () => ({
  type: types.VALIDATE_EMPLOYEE_STARTED,
});

const validateEmployeeSucceeded = (data) => ({
  type: types.VALIDATE_EMPLOYEE_SUCCEEDED,
  payload: data,
});

const validateEmployeeFailed = (error) => ({
  type: types.VALIDATE_EMPLOYEE_FAILED,
  payload: error,
});

export const validateEmployee = (phone) => (dispatch) => {
  dispatch(validateEmployeeStarted());
  return validateEmployeeService.validate(phone).then(
    (response) => {
      dispatch(validateEmployeeSucceeded(response.data.data));
    },
    (error) => {
      dispatch(validateEmployeeFailed(error));
    }
  );
};
