import React from "react";

import "./IconBoxSection.scss";

const IconBoxSection = ({ children }) => (
  <section className="services_section save__nature feature__demo4 icon-box-section">
    <div className="container">
      <div className="row">{children}</div>
    </div>
  </section>
);

export default IconBoxSection;
