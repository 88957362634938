import React from "react";

import FooterApp from "./FooterApp";
import FooterContact from "./FooterContact";

import "./FooterStyles.scss";

const Footer = ({ isMinimalPage }) => (
  <footer
    className={`eate-footer${isMinimalPage ? " footer-minimal" : ""}`}
    id="joinWaitlist"
  >
    <FooterApp
      header="Start Today for Free!"
      showAppDetails
      phoneImgUrl="home-footer-phone.png"
      leftImgs={[
        {
          imgUrl: "home-footer-food-1.png",
          alt: "Sandwich and french fries",
          containerClass: "item_one",
          id: "footer_app_1",
        },
        {
          imgUrl: "home-footer-food-2.png",
          alt: "Chilled drinks",
          containerClass: "item_two",
          id: "footer_app_2",
        },
        {
          imgUrl: "home-footer-food-3.png",
          alt: "Avocado sandwich",
          containerClass: "item_three",
          id: "footer_app_3",
        },
      ]}
      rightImgs={[
        {
          imgUrl: "home-footer-food-4.png",
          alt: "Large salad",
          containerClass: "item_four",
          id: "footer_app_4",
        },
        {
          imgUrl: "home-footer-food-5.png",
          alt: "Banana oatmeal",
          containerClass: "item_five",
          id: "footer_app_5",
        },
        {
          imgUrl: "home-footer-food-6.png",
          alt: "Fruit yogurt cup",
          containerClass: "item_six",
          id: "footer_app_6",
        },
      ]}
    />
    <FooterContact />
  </footer>
);

export default Footer;
