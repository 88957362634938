import React from "react";
import { Link } from "react-router-dom";

import SingleLink from "../Navigation/SingleLink";
import DropdownLinks from "../Navigation/DropdownLinks";
import NestedDropdownLinks from "../Navigation/NestedDropdownLinks";
import DropdownLink from "../Navigation/DropdownLink";

import "./HeaderStyles.scss";

const Header = ({ isMinimalPage }) => (
  <header
    className={`header-nav-center no_blur active-orange2${
      isMinimalPage ? " header-minimal" : ""
    }`}
    id="myNavbar"
  >
    {/*<div class="info-banner">
    banner text
  </div>*/}
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light px-sm-0">
        <Link to="/" className="navbar-brand">
          <img className="logo" src="/assets/img/logo.svg" alt="logo" />
        </Link>
        <button
          className="navbar-toggler menu ripplemenu"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg viewBox="0 0 64 48">
            <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
            <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
            <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
          </svg>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto nav-pills">
            <DropdownLinks text="Platform">
              <DropdownLink route="/restaurants/explore" text="Explore" />
              <DropdownLink route="/restaurants/whyHoste" text="Benefits" />
            </DropdownLinks>
            <SingleLink route="/diners/guestApp" text="Guest App" />
            <SingleLink route="/restaurants/consulting" text="Consulting" />
            <SingleLink route="/restaurants/helpCenter" text="Help Center" />
            <SingleLink route="/restaurants/demo" text="Book a Demo" />
            {/*<DropdownLinks text="Platform">
              <NestedDropdownLinks text="For Guests">
                <DropdownLink route="/diners/whyEate" text="Why Eate" />
                <DropdownLink route="/demo" text="Book a Demo" />
              </NestedDropdownLinks>
              <NestedDropdownLinks text="For Restaurants">
                <DropdownLink route="/restaurants/whyHoste" text="Why Hoste" />
                <DropdownLink route="/demo" text="Book a Demo" />
              </NestedDropdownLinks>
            </DropdownLinks>
            <DropdownLinks text="Explore (being moved)">
              <DropdownLink route="/explore/eate" text="For Guests" />
              <DropdownLink route="/explore/hoste" text="For Restaurants" />
            </DropdownLinks>*/}
            <DropdownLinks text="About">
              <DropdownLink route="/about/ourStory" text="Our Story" />
              <DropdownLink
                route="/about/companyCulture"
                text="Culture and Values"
              />
            </DropdownLinks>
            <SingleLink route="/the-dish" text="Thought-Leadership" />
          </ul>
          <div className="nav_account">
            <a
              href="https://eatedigital.moxo.com"
              target="_blank"
              className="btn btn_sm_primary border-0"
            >
              Sign In
            </a>
            <a
              href="#joinWaitlist"
              className="btn btn_sm_primary bg-orange2 opacity-1 c-white border-0 rounded-pill"
            >
              Get Started
            </a>
          </div>
        </div>
      </nav>
    </div>
  </header>
);

export default Header;
