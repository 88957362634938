import React from "react";

import CustomButton from "../CustomButton/CustomButton";

import "./ContentSliderStyles.scss";

const ContentSlide = ({ columnNumber, individualSlides }) => {
  let mainClass = "swiper-slide";
  const mainSupportClass =
    columnNumber === 1
      ? " swiper-slide-active"
      : columnNumber === 2
      ? " swiper-slide-next"
      : "";
  mainClass += mainSupportClass;

  return (
    <div className={mainClass}>
      {individualSlides.map((s) => (
        <>
          <div className="social-media-post slider-image" key={s.id}>
            {s.imgUrl && (
              <img
                src={require("../../../../Assets/" + s.imgUrl).default}
                className="slider-img"
                alt={s.alt}
              />
            )}
            <div className="social-content">
              {!!s.title && <p className="description">{s.title}</p>}
              {!!s.content && s.content}
            </div>
          </div>
          {!!s.buttonText && !!s.buttonVideoLink && (
            <div className="social-video-button">
              <CustomButton
                customClasses={"btn_video"}
                data-toggle="modal"
                data-src={s.buttonVideoLink}
                data-target="#mdllVideo"
              >
                {s.buttonText}
              </CustomButton>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default ContentSlide;
